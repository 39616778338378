import { useContext, useState, useEffect } from "react"
import { Link } from "react-router-dom"
import moment from "moment"
import axios from "axios"
import {
	DAYCARE_TRIAL_TIME_MORNING,
	DAYCARE_TRIAL_TIME_AFTERNOON,
} from "../../utils/constants"
import { RegistrationContext } from "../../contexts/registrationStatusContext"
import TimePicker from "react-time-picker"
import "react-time-picker/dist/TimePicker.css"
import "react-clock/dist/Clock.css"

const DaycareBookingForm = ({ appointmentType }) => {
	const {
		setShowBookDaycareModal,
		setShowBookTrialDayModal,
		formData,
		setFormData,
		setShowLoginModal,
	} = useContext(RegistrationContext)
	const [step, setStep] = useState(0)
	const [
		showSignWithTrialDayConfirmation,
		setShowSignWithTrialDayConfirmation,
	] = useState(false)
	const [errorMessage, setErrorMessage] = useState("")
	const [selectedDate, setSelectedDate] = useState("")
	const [selectedTime, setSelectedTime] = useState("07:00")
	const [session, setSession] = useState("")
	const [selectedLocation, setSelectedLocation] = useState("milton")
	const [bookErrorMessage, setBookErrorMessage] = useState("")
	const [bookErrorMessageWithFreeTrial, setBookErrorMessageWithFreeTrial] =
		useState(false)

	// const [formData, setFormData] = useState({
	// 	firstName: "",
	// 	lastName: "",
	// 	phone: "",
	// 	email: "",
	// 	petName: "",
	// 	breed: "",
	// 	agreeTerms: false,
	// })

	useEffect(() => {
		if (session === "morning") {
			setSelectedTime("07:00")
		} else {
			setSelectedTime("14:00")
		}
	}, [session])

	const nextStep = () => {
		setStep(step + 1)
	}

	const handleInputChange = (e) => {
		const { name, value, type, checked } = e.target
		setFormData((prevData) => ({
			...prevData,
			[name]: type === "checkbox" ? checked : value,
		}))
	}

	const renderProgressBar = () => (
		<div className="flex items-center justify-between gap-2 mb-4">
			<div
				className={`w-1/3 ${step >= 1 ? "text-green-500" : "text-gray-500"}`}
			>
				1. Date
			</div>
			<div
				className={`w-1/3 ${step >= 2 ? "text-green-500" : "text-gray-500"}`}
			>
				2. Owner
			</div>
			<div
				className={`w-1/3 ${step >= 3 ? "text-green-500" : "text-gray-500"}`}
			>
				3. Confirm
			</div>
		</div>
	)

	const handleTimeChange = (newTime) => {
		setSelectedTime(newTime)
	}

	const synchronizeAndUpdateCals = async () => {
		const formattedDate = moment(selectedDate).format("YYYY-MM-DD")

		// Get the DaycareSpot for the day to get the id
		const daycareSpotsForDate = await axios.get(
			`/schedule/daycare_avail_spots/${formattedDate}`
		)

		// Take the first item in the array
		const foundDaycareSpot = daycareSpotsForDate.data[0]
		const updatedNumOfSpots = foundDaycareSpot.numOfAvailSpots - 1

		// Build the daycare obj to update the num of spots
		const daycareObj = {
			numOfAvailSpots: updatedNumOfSpots,
		}

		// Update the Daycare Spot record
		await axios
			.put(`/schedule/daycare_space/update/${foundDaycareSpot.id}`, daycareObj)
			.then(async () => {
				// Get the Calendar Record that holds the Daycare Info (Num of spots)
				const calDaycareEvents = await axios.get(
					`/schedule/calendars/get/${formattedDate}`
				)
				// Take the first item in the array
				const foundCalEvent = calDaycareEvents.data[0]
				// Build the Calendar to update Obj
				const updatedDaycareCalObj = {
					id: foundCalEvent.id,
					title: `${updatedNumOfSpots} Spots available for Daycare`,
				}
				// Update the Calendar event
				await axios.put(
					`/schedule/calendar/update/${foundCalEvent.id}`,
					updatedDaycareCalObj
				)

				const formattedTitle = `Session: ${
					appointmentType === "free_trial" ? "Free Trial Session" : session
				}  ${
					selectedTime &&
					`Time: ${
						appointmentType === "free_trial"
							? session === "morning"
								? DAYCARE_TRIAL_TIME_MORNING
								: DAYCARE_TRIAL_TIME_AFTERNOON
							: selectedTime
					}`
				} - Ph: ${formData.phone} Email: ${formData.email}`

				const formattedStartDate =
					session === "morning"
						? moment.tz(`${selectedDate}T07:00:00`, "America/Toronto").toDate()
						: moment.tz(`${selectedDate}T13:30:00`, "America/Toronto").toDate()

				const formattedEndDate =
					session === "afternoon"
						? moment.tz(`${selectedDate}T18:30:00`, "America/Toronto").toDate()
						: moment.tz(`${selectedDate}T13:30:00`, "America/Toronto").toDate()

				// Build the new Event
				let obj = {
					title: formattedTitle,
					breedName: `${formData.petName} ${formData.breed}`,
					start: formattedStartDate,
					end: formattedEndDate,
					appointment: "daycare",
					lastModifiedBy: "clientPortal",
					city: selectedLocation,
					name: "daycare",
				}
				try {
					await axios.post(`/schedule/calendars_all/add`, obj)

					// Send confirmation emil
					const emailObj = {
						email: formData.email,
						pet: formData.petName,
						appDetailsStart: moment(formattedStartDate).format("MMMM Do YYYY"),
						appointmentType,
						session,
						selectedLocation,
					}
					await axios.post(`/schedule/daycare/booking_confirmation`, emailObj)
				} catch (err) {
					console.log(err)
				}
			})
	}

	const submitFormHandler = async () => {
		if (!formData.agreeTerms) {
			return alert("You must accept our terms and conditions.")
		}
		if (
			!formData.firstName ||
			!formData.lastName ||
			!formData.phone ||
			!formData.email ||
			!selectedDate
		) {
			return alert("All fields are required!")
		} else {
			const formattedDate = moment(selectedDate).startOf("day")
			const currentDate = moment().startOf("day")

			if (moment(formattedDate).isBefore(currentDate.add(2, "days"))) {
				return alert("This date is unavailable, please try a different date.")
			}

			const daycareRes = await axios.get(
				`/schedule/daycare_calendar/check/${formData.phone}/${formData.petName}`
			)
			if (appointmentType === "daycare") {
				// Check if pet has already been in Trial session before
				// Change this route method so it checks directly from the pet model. CONTINUE HERE....
				if (!daycareRes.data.length) {
					// This pet must book a trial session first before is allowed to book a daycare session
					setShowSignWithTrialDayConfirmation(true)
					return setBookErrorMessageWithFreeTrial(true)
				}
			} else {
				if (daycareRes.data.length) {
					// This pet already had a trial session... must book regular session
					setShowSignWithTrialDayConfirmation(true)
					return setBookErrorMessage(
						"Our records inidicate that this pet already had a free trial session. Please book a regular session through your client portal."
					)
				}
			}

			const response = await axios.get(
				`/schedule/daycare_avail_spots/${formattedDate.format("YYYY-MM-DD")}`
			)
			if (!response.data.length || response.data[0].numOfAvailSpots < 1) {
				setErrorMessage(
					"There is nothing available on this day. Please choose a different date."
				)
			} else {
				setErrorMessage("")
				synchronizeAndUpdateCals()
				setFormData({
					firstName: "",
					lastName: "",
					phone: "",
					email: "",
					petName: "",
					breed: "",
					agreeTerms: false,
				})
				nextStep()
			}
		}
	}

	const renderFormContent = () => {
		switch (step) {
			case 0:
				return (
					<div>
						<h5 className="text-center">Select Location</h5>
						<hr />
						<div className="flex justify-between gap-2 text-xl">
							<button
								className="w-1/2 px-2 py-1 text-white rounded-md bg-pink"
								onClick={() => {
									setStep(1)
									setSelectedLocation("milton")
								}}
							>
								Milton
							</button>
							<button
								className="w-1/2 px-2 py-1 text-white rounded-md bg-main"
								onClick={() => {
									setSelectedLocation("cambridge")
								}}
							>
								Cambridge
							</button>
						</div>
					</div>
				)
			case 1:
				return (
					<div>
						<p>Who will be joining us?</p>
						<input
							type="text"
							className="w-full p-2 border border-gray-300 rounded-md"
							placeholder="Dog name properly spelled"
							onChange={handleInputChange}
							name="petName"
							value={formData.petName}
						/>
						<input
							type="text"
							className="w-full p-2 mt-2 border border-gray-300 rounded-md"
							placeholder="Dog breed"
							onChange={handleInputChange}
							name="breed"
							value={formData.breed}
						/>
						<div className="w-full mt-4">
							<p>Select a date:</p>
							<input
								value={selectedDate}
								onChange={(e) => setSelectedDate(e.target.value)}
								className="w-full p-2 border rounded-md"
								type="date"
							/>
						</div>
						<div className="w-full p-2 mt-4 border rounded-md">
							<p>Select your preferred session</p>
							<p
								onClick={() => setSession("morning")}
								className={`my-2 cursor-pointer hover:bg-black rounded-md text-white ${
									session === "morning" ? "bg-black" : "bg-slate-500"
								} p-2`}
							>
								Morning ({DAYCARE_TRIAL_TIME_MORNING})
							</p>
							<p
								onClick={() => setSession("afternoon")}
								className={`my-2 cursor-pointer hover:bg-black rounded-md text-white ${
									session === "afternoon" ? "bg-black" : "bg-slate-600"
								}  p-2`}
							>
								Afternoon ({DAYCARE_TRIAL_TIME_AFTERNOON})
							</p>
						</div>
						{appointmentType === "daycare" && session && (
							<div className="w-full mt-4">
								<p>
									Select Time (Between {session === "morning" ? "7am" : "2pm"}{" "}
									and {session === "morning" ? "1pm" : "5pm"}):
								</p>
								<TimePicker
									className="w-full text-black border rounded-md"
									onChange={handleTimeChange}
									value={selectedTime}
									maxDetail="hour"
									minTime={`${session === "morning" ? "07:00" : "14:00"}`}
									maxTime={`${session === "morning" ? "11:00" : "17:00"}`}
									clockIcon={<div style={{ display: "none" }} />} // hide clock icon
									disableClock={true} // show only the hour input
									onInvalidInput={() =>
										alert("Please select a time within the accepted hours")
									}
								/>
							</div>
						)}

						<button
							onClick={() => {
								if (!formData.petName || !selectedDate) {
									alert("All fields are required!")
								} else {
									nextStep()
								}
							}}
							className="px-4 py-2 mt-4 text-white bg-green-500 rounded-md hover:bg-green-600"
						>
							Next
						</button>
					</div>
				)
			case 2:
				return (
					<div>
						{bookErrorMessageWithFreeTrial &&
						showSignWithTrialDayConfirmation ? (
							<div>
								<img
									className="w-1/3 mx-auto"
									src="/images/dog_and_stop_sign.png"
									alt="dog stop"
								/>
								Our records indicate that your dog has not attended one of our
								free trial doggie daycare sessions before, and this is mandatory
								before you can start booking Daycare Sessions through this
								portal.
								<p>Would you like to book this free trial session now?</p>
								<button
									onClick={() => {
										setShowBookTrialDayModal(true)
										setShowBookDaycareModal(false)
									}}
									className="btn btn-info"
								>
									Book my free session
								</button>
							</div>
						) : bookErrorMessage && showSignWithTrialDayConfirmation ? (
							<>
								<img
									className="w-1/3 mx-auto"
									src="/images/dog_and_stop_sign.png"
									alt="dog stop"
								/>
								{bookErrorMessage}
								<span
									onClick={() => setShowLoginModal(true)}
									className="cursor-pointer ml-2 text-pink"
								>
									Login Here
								</span>
							</>
						) : (
							<>
								<form>
									<div className="mb-4">
										<label
											htmlFor="firstName"
											className="block text-sm font-medium text-gray-700"
										>
											First Name
										</label>
										<input
											type="text"
											id="firstName"
											name="firstName"
											value={formData.firstName}
											onChange={handleInputChange}
											className="w-full p-2 mt-1 border border-gray-300 rounded-md"
											required
										/>
									</div>
									<div className="mb-4">
										<label
											htmlFor="lastName"
											className="block text-sm font-medium text-gray-700"
										>
											Last Name
										</label>
										<input
											type="text"
											id="lastName"
											name="lastName"
											value={formData.lastName}
											onChange={handleInputChange}
											className="w-full p-2 mt-1 border border-gray-300 rounded-md"
											required
										/>
									</div>
									<div className="mb-4">
										<label
											htmlFor="phone"
											className="block text-sm font-medium text-gray-700"
										>
											Phone
										</label>
										<input
											type="tel"
											id="phone"
											name="phone"
											value={formData.phone}
											onChange={handleInputChange}
											className="w-full p-2 mt-1 border border-gray-300 rounded-md"
											required
										/>
									</div>
									<div className="mb-4">
										<label
											htmlFor="email"
											className="block text-sm font-medium text-gray-700"
										>
											Email
										</label>
										<input
											type="email"
											id="email"
											name="email"
											value={formData.email}
											onChange={handleInputChange}
											className="w-full p-2 mt-1 border border-gray-300 rounded-md"
											required
										/>
									</div>
									<div className="mb-4">
										<label className="flex items-center">
											<input
												type="checkbox"
												name="agreeTerms"
												checked={formData.agreeTerms}
												onChange={handleInputChange}
												className="mr-2"
												required
											/>
											<span className="text-sm">
												I agree to the{" "}
												<Link target="_blank" to="/terms-and-conditions">
													Daycare Terms and Conditions
												</Link>
											</span>
										</label>
									</div>
								</form>

								<p className="capitalize">
									<b>Booking Details:</b> <br />
									Location: {selectedLocation} <br />
									Date: {selectedDate} <br />
									Session: {session}
								</p>
								<div>
									<span
										onClick={() => setStep(step - 1)}
										className="mr-2 cursor-pointer text-main"
									>
										&#x2190; Change Date
									</span>{" "}
								</div>
								<button
									onClick={submitFormHandler}
									className="px-4 py-2 mt-4 text-white bg-green-500 rounded-md hover:bg-green-600"
								>
									Next
								</button>
								<p className="mt-4 text-red-600">{errorMessage}</p>
							</>
						)}
					</div>
				)
			case 3:
				return (
					<div>
						<h4>You are confirmed!</h4>
						<p>
							Session: <span className="font-bold capitalize">{session}</span>
						</p>
						<p>
							Date:
							<span className="ml-2 font-bold capitalize">
								{moment(selectedDate).format("YYYY-MM-DD")}
							</span>
						</p>
						<p>
							Time:{" "}
							{appointmentType === "daycare" ? (
								selectedTime
							) : (
								<span className="font-bold">
									{session === "morning"
										? DAYCARE_TRIAL_TIME_MORNING
										: DAYCARE_TRIAL_TIME_AFTERNOON}
								</span>
							)}
						</p>
						<p className="capitalize">
							Location:{" "}
							<span className="font-bold">
								{selectedLocation === "milton"
									? "264 Bronte St S. Unit 15, Milton, ON"
									: "383 Elgin St N. Unit 1, Cambridge, ON"}
							</span>{" "}
						</p>
						<hr />
						<p
							className="text-center text-blue-600 cursor-pointer"
							onClick={() => {
								setShowBookDaycareModal(false)
								setShowBookTrialDayModal(false)
							}}
						>
							Close
						</p>
					</div>
				)
			default:
				return null
		}
	}

	return (
		<div className="p-4 mx-auto border border-gray-300 rounded-md  sm:p-0">
			<h5 className="text-center">
				Book your Daycare{" "}
				{appointmentType === "daycare" ? "session" : "trial day"} at <br />
				Amazing Pet Grooming
			</h5>
			<hr />
			{step !== 0 && renderProgressBar()}
			<hr />
			{selectedLocation === "milton" ? (
				renderFormContent()
			) : (
				<div>
					<span
						onClick={() => setSelectedLocation("milton")}
						className="mr-2 cursor-pointer text-main"
					>
						&#x2190; Back
					</span>{" "}
					This location is unavailable at the moment
				</div>
			)}
		</div>
	)
}

export default DaycareBookingForm
