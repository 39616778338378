import React, { useState, useEffect, useContext } from "react";
import TableCommission from "./TableCommission";
import ButtonToAddCommission from "./ButtonToAdd";
import ModalToAddCommission from "./ModalToAdd";
import ModalToEditCommission from "./ModalToEdit";
import axios from "axios";
import moment from "moment";
import { SiAdblock } from "react-icons/si";
import { useLocation, useNavigate } from "react-router-dom";
import { RegistrationContext } from "../../contexts/registrationStatusContext";

const DAYCARE = "daycare";
const GROOMER_1 = "paola"; //Dayoff = 3 Manager
const GROOMER_2 = "claudia"; //Dayoff = 2 Manager
const GROOMER_3 = "diana"; //Dayoff = 1
const GROOMER_4 = "frank"; //Dayoff = 1
const GROOMER_5 = "baths"; //Dayoff = 7
const GROOMER_6 = "peppa"; //Dayoff = 4
const GROOMER_7 = "adriana"; //Dayoff = 6
const GROOMER_8 = "mercedes"; //Dayoff = 6
const GROOMER_9 = "baths_cambridge"; //Dayoff = 7
const TODAY_DATE = moment(new Date()).format("YYYY-MM-DD");

const NewCommissionPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { commissions, setCommissions } = useContext(RegistrationContext);

  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);
  const [authorized, setAuthorized] = useState(false);
  const [activeCity, setActiveCity] = useState("milton");
  const [modalToAddCommission, setModalToAddCommission] = useState(false);
  const [modalToEditCommission, setModalToEditCommission] = useState(false);
  const [clientNumber, setClientNumber] = useState("");
  const [clientPhone, setClientPhone] = useState("");
  const [groomer, setGroomer] = useState("");
  const [nameBreed, setNameBreed] = useState("");
  const [arrivalTime, setArrivalTime] = useState("");
  const [pickupTime, setPickupTime] = useState("");
  const [cost, setCost] = useState("");
  const [tip, setTip] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [loadingAxiosReq, setLoadingAxiosReq] = useState();
  const [groomerId, setGroomerId] = useState("");
  const [jobType, setJobType] = useState("");

  const accessString = localStorage.getItem("JWT");

  const fetchAuth = async () => {
    await axios
      .get("/auth/employees_profile", {
        headers: { Authorization: `JWT ${accessString}` },
      })
      .then((res) => setJobType(res.data.jobType))
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    if (
      jobType === "admin" ||
      jobType === "receptionist" ||
      jobType === "receptionistCalendars"
    ) {
      setAuthorized(true);
    }
  }, [jobType]);

  useEffect(() => {
    const accessString = localStorage.getItem("JWT");

    if ("scrollRestoration" in window.history) {
      window.history.scrollRestoration = "manual";
    }
    window.scrollTo(0, 0);

    if (accessString == null) {
      setIsLoading(false);
      setError(true);
    } else {
      try {
        fetchAuth();
        setIsLoading(false);
        setError(false);
        getAllCommissions();
      } catch (error) {
        console.error(error.response);
        setError(true);
      }
    }
  }, []);

  useEffect(() => {
    if (location.state) {
      if (location.state.groomer === "peppa") {
        setActiveCity("cambridge");
      }
      setGroomer(location.state.groomer || "");
      setNameBreed(location.state.nameBreed || "");
      setArrivalTime(location.state.arrivalTime || "");
      setClientNumber(location.state.clientId || "");
      setClientPhone(location.state.clientPhone || "");

      setModalToAddCommission(true);

      navigate(location.pathname, { replace: true, state: null });
    }
  }, [location.state]);

  useEffect(() => {
    if (!modalToEditCommission && !location.state) {
      setClientNumber("");
      setClientPhone("");
      setNameBreed("");
      setArrivalTime("");
      setPickupTime("");
      setCost("");
      setTip("");
      setGroomer("");
      setGroomerId("");
    }
  }, [modalToEditCommission]);

  useEffect(() => {
    if (!modalToAddCommission && !location.state) {
      setClientNumber("");
      setClientPhone("");
      setNameBreed("");
      setArrivalTime("");
      setPickupTime("");
      setCost("");
      setTip("");
      setGroomer("");
      setErrMsg("");
    }
  }, [modalToAddCommission]);

  const getAllCommissions = async () => {
    await axios
      .get("/auth/salaries/hourly/commission/" + TODAY_DATE)
      .then((res) => {
        console.log(res);
        setCommissions(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const toggleModalToAddCommission = (groomer) => {
    setGroomer(groomer);
    setModalToAddCommission(!modalToAddCommission);
  };

  const toggleModalToEditCommission = (groomer) => {
    setGroomer(groomer);
    setModalToEditCommission(!modalToEditCommission);
  };
  //ADD COMMISSION TO GROOMERS
  const submitFormAll = async (e) => {
    e.preventDefault();
    if (!clientNumber || !nameBreed || !arrivalTime || !pickupTime) {
      return setErrMsg("Missing some of the required fields!");
    }

    if (isNaN(cost)) {
      return setErrMsg("Enter numbers only in this field!");
    }
    let prepaidWithPassCost = null;
    let passDescription = null;
    if (groomer === DAYCARE) {
      // Retrieve membership or pass
      const foundMembership = await axios.get(
        `/auth/api/membership_by_client_id/${clientNumber}`,
        {
          headers: { JWT: accessString },
        }
      );
      if (foundMembership.data) {
        // Check if visits are available on the pass/membership
        if (foundMembership.data.numOfVisitsLeft > 0) {
          prepaidWithPassCost =
            foundMembership.data.value /
            foundMembership.data.originalNumOfVisits;
          const memershipObj = {
            numOfVisitsLeft: foundMembership.data.numOfVisitsLeft - 1,
          };
          passDescription = foundMembership.data.name;
          // Update membership to reduce the number of visits
          await axios.put(
            `/api/membership/${foundMembership.data.id}`,
            memershipObj
          );
        }
      }
    }
    let employeeWage = null;

    const dayOff =
      groomer === GROOMER_1
        ? 3
        : groomer === GROOMER_2
        ? 2
        : groomer === GROOMER_3
        ? 1
        : groomer === GROOMER_4
        ? 1
        : groomer === GROOMER_5
        ? 7
        : groomer === GROOMER_6 || groomer === GROOMER_7
        ? 6
        : 7;

    const commission = {
      groomerName: groomer,
      date: moment(new Date()).format("YYYY-MM-DD"),
      clientNumber: clientNumber,
      clientPhone: clientPhone,
      nameBreed: nameBreed + ` (${passDescription})`,
      arrivalTime: arrivalTime,
      pickupTime: pickupTime,
      called: false,
      cost: prepaidWithPassCost,
      tip: null,
      salaryType: groomer === GROOMER_5 ? "hourly" : "commission",
      baseCommissionPercent: 50,
      hoursWorked: 0,
      hoursWage: employeeWage,
      jobPosition:
        groomer === GROOMER_1
          ? "manager"
          : groomer === GROOMER_5
          ? "bather"
          : "groomer",
      dayOff: dayOff,
    };
    setLoadingAxiosReq(true);

    await axios
      .post(`/auth/salaries/add`, commission)
      .then((res) => {
        setLoadingAxiosReq(false);
        setCommissions([...commissions, res.data]);
        setModalToAddCommission(false);
      })
      .catch((err) => {
        setLoadingAxiosReq(false);
        console.log(err);
      });
  };

  const handleChange = (e) => {
    const value = e.target.value;
    const inputName = e.target.name;
    if (inputName === "clientNumber") {
      setClientNumber(value);
    }
    if (inputName === "clientPhone") {
      setClientPhone(value);
    }
    if (inputName === "nameBreed") {
      setNameBreed(value);
    }
    if (inputName === "arrivalTime") {
      setArrivalTime(value);
    }
    if (inputName === "pickupTime") {
      setPickupTime(value);
    }
    if (inputName === "cost") {
      setCost(value);
    }
    if (inputName === "tip") {
      setTip(value);
    }
  };

  const getGroomerDataAndOpenModal = async (id, groomerName) => {
    setGroomerId(id);
    setGroomer(groomerName);
    setModalToEditCommission(!modalToEditCommission);
    await axios
      .get(`/auth/report/retrieve/oneEmployee/${id}`)
      .then((res) => {
        setClientNumber(res.data.clientNumber);
        setClientPhone(res.data.clientPhone);
        setNameBreed(res.data.nameBreed);
        setArrivalTime(res.data.arrivalTime);
        setPickupTime(res.data.pickupTime);
        setCost(res.data.cost);
        setTip(res.data.tip);
        setGroomer(res.data.groomerName);
      })
      .catch((err) => console.log(err));
  };

  const submitUpdateHandler = async (e) => {
    e.preventDefault();
    let infoUpdated = {
      clientNumber: clientNumber,
      clientPhone: clientPhone,
      nameBreed: nameBreed,
      arrivalTime: arrivalTime,
      pickupTime: pickupTime,
      cost: cost,
      tip: tip,
    };

    await axios
      .put(`/auth/salaries/update/${groomerId}`, infoUpdated)
      .then(() => {
        getAllCommissions();
        setModalToEditCommission(false);
      })
      .catch((err) => console.log(err));
  };

  const callToggle = async (id, wasOwnerCalled) => {
    const callStatus = wasOwnerCalled ? { called: true } : { called: false };
    await axios
      .put(`/auth/salaries/update/${id}`, callStatus)
      .then(() => {
        getAllCommissions();
        setModalToEditCommission(false);
      })
      .catch((err) => console.log(err));
  };

  if (isLoading) {
    return (
      <div
        style={{
          marginTop: "120px",
          marginLeft: "10%",
          fontSize: "30px",
          height: "100vh",
        }}
      >
        Loading...if it does not respond, refresh this page, or sign in again
      </div>
    );
  }

  if (error) {
    console.log(error);
    return (
      <div
        style={{
          marginLeft: "10%",
          marginTop: "120px",
          fontSize: "30px",
          height: "100vh",
        }}
      >
        ...Problem fetching user data. Please login again{" "}
        <span role="img ml-2" aria-label="Face With Rolling Eyes Emoji">
          🙄
        </span>
      </div>
    );
  }

  if (!authorized) {
    return (
      <div className="mt-24 text-3xl text-center">
        <SiAdblock size={40} className="inline-block text-red-500" /> <br />{" "}
        Unauthorized
      </div>
    );
  }

  return (
    <div className="commission-parent-container">
      <h1 className="text-align-center controlPanelHeading margin-top-big">
        DAILY REPORT <i className="fas fa-dollar-sign"></i>{" "}
        <span>{activeCity.toLocaleUpperCase()}</span>
      </h1>
      <button
        className={`btn m-4 ${
          activeCity === "milton" ? "btn-warning" : "btn-info"
        }`}
        onClick={() =>
          setActiveCity(activeCity === "milton" ? "cambridge" : "milton")
        }
      >
        Switch to {activeCity === "milton" ? "Cambridge" : "Milton"}
      </button>

      {/* Buttons for Groomers */}
      <div className="flex flex-wrap gap-1 text-align-center justify-evenly marginLargeInMobile">
        {activeCity === "milton" ? (
          <>
            <ButtonToAddCommission
              colValue="1"
              name={"daycare"}
              class={`commission-btn_${DAYCARE}`}
              toggleModal={() => toggleModalToAddCommission(DAYCARE)}
            />
            <ButtonToAddCommission
              colValue="1"
              name={GROOMER_1}
              class={`commission-btn_${GROOMER_1}`}
              toggleModal={() => toggleModalToAddCommission(GROOMER_1)}
            />
            <ButtonToAddCommission
              colValue="2"
              name={GROOMER_2}
              class={`commission-btn_${GROOMER_2}`}
              toggleModal={() => toggleModalToAddCommission(GROOMER_2)}
            />
            <ButtonToAddCommission
              colValue="2"
              name={GROOMER_7}
              class={`commission-btn_${GROOMER_7}`}
              toggleModal={() => toggleModalToAddCommission(GROOMER_7)}
            />
            <ButtonToAddCommission
              colValue="2"
              name={GROOMER_3}
              class={`commission-btn_${GROOMER_3}`}
              toggleModal={() => toggleModalToAddCommission(GROOMER_3)}
            />
            <ButtonToAddCommission
              colValue="2"
              name={GROOMER_5}
              class={`commission-btn_groomer2`}
              toggleModal={() => toggleModalToAddCommission(GROOMER_5)}
            />
            <ButtonToAddCommission
              colValue="1"
              name={GROOMER_4}
              class={`commission-btn_groomer`}
              toggleModal={() => toggleModalToAddCommission(GROOMER_4)}
            />
            <ButtonToAddCommission
              colValue="2"
              name={GROOMER_8}
              class={`commission-btn_${GROOMER_8}`}
              toggleModal={() => toggleModalToAddCommission(GROOMER_8)}
            />
          </>
        ) : (
          <>
            <ButtonToAddCommission
              colValue="2"
              name={GROOMER_1}
              class={`commission-btn_${GROOMER_1}`}
              toggleModal={() => toggleModalToAddCommission(GROOMER_1)}
            />
            {/* <ButtonToAddCommission
							colValue="2"
							name={GROOMER_2}
							class={`commission-btn_${GROOMER_2}`}
							toggleModal={() => toggleModalToAddCommission(GROOMER_2)}
						/> */}
            <ButtonToAddCommission
              colValue="2"
              name={GROOMER_6}
              class={`commission-btn_groomer4`}
              toggleModal={() => toggleModalToAddCommission(GROOMER_6)}
            />
            <ButtonToAddCommission
              colValue="2"
              name={GROOMER_7}
              class={`commission-btn_${GROOMER_7}`}
              toggleModal={() => toggleModalToAddCommission(GROOMER_7)}
            />
            <ButtonToAddCommission
              colValue="2"
              name={GROOMER_8}
              class={`commission-btn_${GROOMER_8}`}
              toggleModal={() => toggleModalToAddCommission(GROOMER_8)}
            />
            <ButtonToAddCommission
              colValue="2"
              name={GROOMER_9 ? "Baths" : ""}
              class={`commission-btn_${GROOMER_9}`}
              toggleModal={() => toggleModalToAddCommission(GROOMER_9)}
            />
          </>
        )}
      </div>

      {activeCity === "milton" ? (
        <>
          {commissions.filter((data) => data.groomerName === DAYCARE).length ? (
            <TableCommission
              tableClass={`commission-groomers-info_${DAYCARE}`}
              tableName={DAYCARE}
              groomerData={commissions.filter(
                (data) => data.groomerName === DAYCARE
              )}
              getGroomerDataAndOpenModal={getGroomerDataAndOpenModal}
              loadingAxiosReq={loadingAxiosReq}
              callToggle={callToggle}
            />
          ) : null}
          {commissions.filter((data) => data.groomerName === GROOMER_2)
            .length ? (
            <TableCommission
              tableClass={`commission-groomers-info_${GROOMER_2}`}
              tableName={GROOMER_2}
              groomerData={commissions.filter(
                (data) => data.groomerName === GROOMER_2
              )}
              getGroomerDataAndOpenModal={getGroomerDataAndOpenModal}
              loadingAxiosReq={loadingAxiosReq}
              callToggle={callToggle}
            />
          ) : null}
          {commissions.filter((data) => data.groomerName === GROOMER_1).length >
          0 ? (
            <TableCommission
              tableClass={`commission-groomers-info_${GROOMER_1}`}
              tableName={GROOMER_1}
              groomerData={commissions.filter(
                (data) => data.groomerName === GROOMER_1
              )}
              getGroomerDataAndOpenModal={getGroomerDataAndOpenModal}
              loadingAxiosReq={loadingAxiosReq}
              callToggle={callToggle}
            />
          ) : null}
          {commissions.filter((data) => data.groomerName === GROOMER_3).length >
          0 ? (
            <TableCommission
              tableClass={`commission-groomers-info_${GROOMER_3}`}
              tableName={GROOMER_3}
              groomerData={commissions.filter(
                (data) => data.groomerName === GROOMER_3
              )}
              getGroomerDataAndOpenModal={getGroomerDataAndOpenModal}
              loadingAxiosReq={loadingAxiosReq}
              callToggle={callToggle}
            />
          ) : null}
          {commissions.filter((data) => data.groomerName === GROOMER_5).length >
          0 ? (
            <TableCommission
              tableClass={`commission-groomers-info_groomer2`}
              tableName={GROOMER_5}
              groomerData={commissions.filter(
                (data) => data.groomerName === GROOMER_5
              )}
              getGroomerDataAndOpenModal={getGroomerDataAndOpenModal}
              loadingAxiosReq={loadingAxiosReq}
              callToggle={callToggle}
            />
          ) : null}
          {commissions.filter((data) => data.groomerName === GROOMER_4).length >
          0 ? (
            <TableCommission
              tableClass={`commission-groomers-info_groomer`}
              tableName={GROOMER_4}
              groomerData={commissions.filter(
                (data) => data.groomerName === GROOMER_4
              )}
              getGroomerDataAndOpenModal={getGroomerDataAndOpenModal}
              loadingAxiosReq={loadingAxiosReq}
              callToggle={callToggle}
            />
          ) : null}
          {commissions.filter((data) => data.groomerName === GROOMER_7).length >
          0 ? (
            <TableCommission
              tableClass={`commission-groomers-info_${GROOMER_7}`}
              tableName={GROOMER_7}
              groomerData={commissions.filter(
                (data) => data.groomerName === GROOMER_7
              )}
              getGroomerDataAndOpenModal={getGroomerDataAndOpenModal}
              loadingAxiosReq={loadingAxiosReq}
              callToggle={callToggle}
            />
          ) : null}
          {commissions.filter((data) => data.groomerName === GROOMER_8).length >
          0 ? (
            <TableCommission
              tableClass={`commission-groomers-info_${GROOMER_8}`}
              tableName={GROOMER_8}
              groomerData={commissions.filter(
                (data) => data.groomerName === GROOMER_8
              )}
              getGroomerDataAndOpenModal={getGroomerDataAndOpenModal}
              loadingAxiosReq={loadingAxiosReq}
              callToggle={callToggle}
            />
          ) : null}
        </>
      ) : (
        <>
          {/* {commissions.filter((data) => data.groomerName === GROOMER_2).length >
					0 ? (
						<TableCommission
							tableClass={`commission-groomers-info_${GROOMER_2}`}
							tableName={GROOMER_2}
							groomerData={commissions.filter(
								(data) => data.groomerName === GROOMER_2
							)}
							getGroomerDataAndOpenModal={getGroomerDataAndOpenModal}
							loadingAxiosReq={loadingAxiosReq}
							callToggle={callToggle}
						/>
					) : null} */}
          {commissions.filter((data) => data.groomerName === GROOMER_1).length >
          0 ? (
            <TableCommission
              tableClass={`commission-groomers-info_${GROOMER_1}`}
              tableName={GROOMER_1}
              groomerData={commissions.filter(
                (data) => data.groomerName === GROOMER_1
              )}
              getGroomerDataAndOpenModal={getGroomerDataAndOpenModal}
              loadingAxiosReq={loadingAxiosReq}
              callToggle={callToggle}
            />
          ) : null}

          {commissions.filter((data) => data.groomerName === GROOMER_6).length >
          0 ? (
            <TableCommission
              tableClass={`commission-groomers-info_groomer4`}
              tableName={GROOMER_6}
              groomerData={commissions.filter(
                (data) => data.groomerName === GROOMER_6
              )}
              getGroomerDataAndOpenModal={getGroomerDataAndOpenModal}
              loadingAxiosReq={loadingAxiosReq}
              callToggle={callToggle}
            />
          ) : null}
          {commissions.filter((data) => data.groomerName === GROOMER_7).length >
          0 ? (
            <TableCommission
              tableClass={`commission-groomers-info_${GROOMER_7}`}
              tableName={GROOMER_7}
              groomerData={commissions.filter(
                (data) => data.groomerName === GROOMER_7
              )}
              getGroomerDataAndOpenModal={getGroomerDataAndOpenModal}
              loadingAxiosReq={loadingAxiosReq}
              callToggle={callToggle}
            />
          ) : null}
          {commissions.filter((data) => data.groomerName === GROOMER_8).length >
          0 ? (
            <TableCommission
              tableClass={`commission-groomers-info_${GROOMER_8}`}
              tableName={GROOMER_8}
              groomerData={commissions.filter(
                (data) => data.groomerName === GROOMER_8
              )}
              getGroomerDataAndOpenModal={getGroomerDataAndOpenModal}
              loadingAxiosReq={loadingAxiosReq}
              callToggle={callToggle}
            />
          ) : null}
          {commissions.filter((data) => data.groomerName === GROOMER_9).length >
          0 ? (
            <TableCommission
              tableClass={`commission-groomers-info_${GROOMER_9}`}
              tableName={GROOMER_9}
              groomerData={commissions.filter(
                (data) => data.groomerName === GROOMER_9
              )}
              getGroomerDataAndOpenModal={getGroomerDataAndOpenModal}
              loadingAxiosReq={loadingAxiosReq}
              callToggle={callToggle}
            />
          ) : null}
        </>
      )}

      {modalToAddCommission && (
        <ModalToAddCommission
          groomerName={groomer}
          openModal={modalToAddCommission}
          toggleModalToAddCommission={toggleModalToAddCommission}
          submitFormAll={submitFormAll}
          valueClientNumber={clientNumber}
          valueClientPhone={clientPhone}
          handleChange={handleChange}
          valueNameBreed={nameBreed}
          valueArrivalTime={arrivalTime}
          valuePickupTime={pickupTime}
          loadingAxiosReq={loadingAxiosReq}
          errMsg={errMsg}
        />
      )}

      <ModalToEditCommission
        groomerName={groomer}
        openModal={modalToEditCommission}
        toggleModalToEditCommission={toggleModalToEditCommission}
        submitFormAll={submitUpdateHandler}
        valueClientNumber={clientNumber}
        valueClientPhone={clientPhone}
        handleChange={handleChange}
        valueDate={TODAY_DATE}
        valueNameBreed={nameBreed}
        valueArrivalTime={arrivalTime}
        valuePickupTime={pickupTime}
        valueCost={cost}
        valueTip={tip}
        loadingAxiosReq={loadingAxiosReq}
        errMsg={errMsg}
      />
    </div>
  );
};

export default NewCommissionPage;
