import React from "react";
import { Link } from "react-router-dom";
import { CustomButton } from "./CustomButton";
import ImageGradient from "./ImageGradient";

const TeamMemberCard = ({ member, reverse = false, className }) => {
  return (
    <div
      className={`w-full flex flex-col md:flex-row gap-[50px] md:items-stretch md:justify-between ${className}`}
    >
      <div
        className={`w-full md:w-1/2 h-[300px] relative rounded-default select-none overflow-hidden ${
          reverse ? "md:-order-last" : "md:order-last"
        }`}
      >
        <ImageGradient color={member.color} image={member.image} />
      </div>

      <div
        className={`w-full md:w-1/2 flex text-main flex-col justify-between`}
      >
        <div>
          <h5>{member.name}</h5>
          <p dangerouslySetInnerHTML={{ __html: member.description }}></p>
        </div>
        <Link to="/schedule" className="hover:no-underline">
          <CustomButton onClick={() => {}} hasArrow fullWidth>
            Book Grooming
          </CustomButton>
        </Link>
      </div>
    </div>
  );
};

export default TeamMemberCard;
