import React, { useLayoutEffect, useRef } from "react"
import { Helmet } from "react-helmet"
import ImageInText from "../../components/ImageInText"
import TeamMemberCard from "../../components/TeamMemberCard"
import { teamMembers } from "../../utils/constants"
import { gsap, Power4 } from "gsap"
import SplitType from "split-type"

const index = () => {
	const container = useRef(null)
	useLayoutEffect(() => {
		let ctx = gsap.context(() => {
			const tl = gsap.timeline({ defaults: { ease: Power4.easeOut } })

			const splitTitle = new SplitType(".lineParentAboutTitle", {
				types: "lines",
			})

			const splitMeetTeamTitle = new SplitType(".lineParentMeetTeamTitle", {
				types: "lines",
			})

			tl.fromTo(
				splitTitle.lines,
				{
					y: 100,
					opacity: 0,
				},
				{
					y: 0,
					opacity: 1,
					duration: 1,
					delay: 0.2,
					stagger: 0.2,
				}
			)
			tl.fromTo(
				"#aboutText",
				{
					y: 50,
					opacity: 0,
				},
				{
					y: 0,
					opacity: 1,
					duration: 1,
				},
				"-=0.5"
			)
			tl.fromTo(
				splitMeetTeamTitle.lines,
				{
					y: 50,
					opacity: 0,
				},
				{
					y: 0,
					opacity: 1,
					duration: 1,
					stagger: 0.2,
				},
				"-=0.7"
			)

			const tlScroll = gsap.timeline({
				scrollTrigger: {
					trigger: ".trigger",
					start: "top 80%",
				},
			})

			tlScroll.fromTo(
				".teamMemberCard",
				{
					y: 50,
					opacity: 0,
				},
				{
					y: 0,
					opacity: 1,
					duration: 0.7,
					stagger: 0.2,
				}
			)
		}, container)

		return () => ctx.revert()
	}, [])

	return (
		<section
			className="w-full h-full flex flex-col gap-[80px] md:gap-[100px] p-phone md:p-tablet lg:px-desktop lg:py-tablet"
			ref={container}
		>
			<Helmet>
				<title>About Us | Amazing Pet Grooming</title>
				<meta
					name="description"
					content="With more than 22 years of experience, you can be sure your dog or cat is in the best hands."
				/>
			</Helmet>
			<main className="w-full h-full bg-white flex flex-col gap-[50px]">
				<h4
					className="text-main text-[38px] pt-24 leading-[50px] md:text-[70px] md:leading-[70px] font-black text-center"
					id="aboutTitle"
				>
					About us
				</h4>
				<span
					className="text-main text-[30px] leading-[30px] md:text-[35px] md:leading-[35px] align-middle mb-0"
					id="aboutText"
				>
					With more than 30 years of experience in the animals field, you can
					absolutely be sure that your dogs and cats are in the best hands. Our
					open concept allows you to watch your dog / cat while being pampered.
					Our environment provides your pets with a unique experience where all
					the stress from other pet groomers simply disappear and your pets are
					just as happy coming in, as they are going home. Additionally, our
					doggie daycare services are designed to provide a fun and stimulating
					environment for your furry friends. Our dedicated staff understands
					the importance of socialization and exercise for dogs, and we ensure
					that your pets have a blast interacting with other dogs in a safe and
					supervised setting. We offer a range of activities and play areas,
					keeping your dogs engaged and active throughout the day.
				</span>
			</main>
			<section className="w-full h-full flex flex-col gap-[40px] md:gap-[60px]">
				<h4
					className="text-main text-[30px] md:text-[45px] font-black text-center"
					id="meetTeamTitle"
				>
					Meet the team
				</h4>
				<div className="w-full h-full flex flex-col gap-[80px] trigger">
					{Object.values(teamMembers).map(
						(member, index) =>
							member.description &&
							(index % 2 === 0 ? (
								<TeamMemberCard
									member={member}
									reverse
									className="teamMemberCard"
									key={member.name}
								/>
							) : (
								<TeamMemberCard
									member={member}
									className="teamMemberCard"
									key={member.name}
								/>
							))
					)}
				</div>
			</section>
		</section>
	)
}

export default index
