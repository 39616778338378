import React, { useEffect, useState } from "react";

const DaycareShowcase = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isImageHovered, setIsImageHovered] = useState(false);

  const images = [
    "/images/daycare_photo8.JPG",
    "/images/daycare_photo2.JPG",
    "/images/daycare_photo3.JPG",
    "/images/daycare_photo4.JPG",
    "/images/daycare_photo6.JPG",
    "/images/daycare_photo5.JPG",
    "/images/daycare_photo7.JPG",
    "/images/daycare_photo1.JPG",
  ];

  const handleDotClick = (index) => {
    setCurrentImageIndex(index);
  };

  useEffect(() => {
    if (!isImageHovered) {
      const interval = setInterval(() => {
        setCurrentImageIndex((prevIndex) =>
          prevIndex === images.length - 1 ? 0 : prevIndex + 1
        );
      }, 5000);
      return () => clearInterval(interval);
    }
  }, [isImageHovered]);

  return (
    <section className="relative flex flex-col items-stretch justify-between w-full md:flex-row py-phone md:py-tablet">
      <div className="w-full md:w-[30%] h-full px-phone pl-mobile md:pr-0 md:pl-tablet lg:pl-desktop">
        <h2 className="mb-4 text-4xl font-black text-black">
          Take a look for yourself!
        </h2>
      </div>
      <div
        className="w-full relative md:w-[70%] h-[70vh] overflow-hidden"
        onMouseEnter={() => setIsImageHovered(true)}
        onMouseLeave={() => setIsImageHovered(false)}
      >
        <div className="flex w-full h-full transition-transform duration-500 ease-out">
          {images.map((image, index) => (
            <img
              key={index}
              src={image}
              alt={`Image ${index + 1}`}
              className={`${
                index === currentImageIndex ? "active" : "hidden"
              } w-full h-full object-cover`}
            />
          ))}
        </div>
        <div
          className="absolute flex transform -translate-x-1/2 dots p-[10px] bottom-[10px] left-1/2 bg-black/30 rounded-full items-center"
          style={{
            backdropFilter: "blur(4px)",
          }}
        >
          {images.map((_, index) => (
            <span
              key={index}
              className={`${
                index === currentImageIndex
                  ? "bg-main w-[9px] h-[9px]"
                  : "bg-white/50 w-[10px] h-[10px]"
              }  rounded-full cursor-pointer mx-[5px]`}
              onClick={() => handleDotClick(index)}
            ></span>
          ))}
        </div>
      </div>
      <img
        className="absolute bottom-0 left-0 w-[15%] pointer-events-none hidden md:block"
        src="/images/golden_retriever.png"
      />
    </section>
  );
};

export default DaycareShowcase;
