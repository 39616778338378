import { useContext, useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  FormGroup,
  Input,
  Label,
} from "reactstrap";
import { Link, useLocation } from "react-router-dom";
import LoadPage from "../../LoadingPage";
import { MdCancel } from "react-icons/md";
import { FaArrowsAltH, FaLongArrowAltLeft, FaCopy } from "react-icons/fa";
import moment from "moment";
import Select from "react-select";
import MainCalendar from "../../Calendars/MainCalendar";
import {
  optionsGroomers,
  optionsGroomersCambridge,
} from "../../../pages/Schedule/Schedule.options";
import optionsNoStaffOnly from "../../Calendars/CalendarOptions/serviceOptionsNoStaffOnly";
import { RegistrationContext } from "../../../contexts/registrationStatusContext";
import axios from "axios";

export default function Index({
  modalToEditEvent,
  toggleModalToEdit,
  props,
  onSubmitModalToEdit,
  appointment,
  setAppointment,
  optionsAdmin,
  optionsTrainee,
  optionsNoSchedule,
  options,
  slotEvent,
  userGroomers,
  jobType,
  title,
  setTitle,
  titleInputEmpty,
  setTitleInputEmpty,
  breedName,
  setBreedName,
  setBreedInputEmpty,
  breedInputEmpty,
  lastModifiedBy,
  toggleHistoryHandler,
  eventHistoryToggle,
  appointmentModifiers,
  loadingAxiosReq,
  handleDeleteEvent,
  start,
  isDaycare,
  updateDaycareHandler,
  numOfSpots,
  setNumOfSpots,
  daycareSpotId,
  extraDetails,
}) {
  const location = useLocation();
  const { commissions, setCommissions } = useContext(RegistrationContext);
  const [triggerFetchEvents, setTriggerFetchEvents] = useState(false);
  const [showTransfer, setShowTransfer] = useState(false);
  const [newGroomer, setNewGroomer] = useState("milton");
  const [appointmentDetailsObj, setAppointmentDetailsObj] = useState(undefined);
  const [parsedDetails, setParsedDetails] = useState({});

  useEffect(() => {
    if (slotEvent.title) {
      setParsedDetails(parseDetails(slotEvent.title));
    }
  }, [slotEvent]);

  useEffect(() => {
    if (!showTransfer || !modalToEditEvent) {
      setAppointmentDetailsObj(undefined);
      setShowTransfer(false);
    }
  }, [showTransfer, modalToEditEvent]);

  useEffect(() => {
    if (props.groomer) {
      setNewGroomer(props.groomer);
    }
  }, [props.groomer]);

  const copyAppointmentDetails = async (e) => {
    e.preventDefault();
    setAppointmentDetailsObj(slotEvent);
    alert("Appointment successfully copied!");
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      alert("Copied to clipboard!");
    });
  };

  const parseDetails = (input) => {
    const phoneMatch = input.match(/\b\d{10}\b/);
    const clientIdMatch = input.match(/\b\d{3,5}\b/);

    const phone = phoneMatch ? phoneMatch[0] : "";
    const clientId = clientIdMatch ? clientIdMatch[0] : "";

    return { phone, clientId };
  };

  const commissionExists = () => {
    if (commissions.length === 0) return false;

    const cleanedNameBreed = slotEvent.breedName;
    return commissions.some(
      (commission) =>
        commission.nameBreed.replace(" (null)", "") === cleanedNameBreed &&
        commission.clientPhone === parsedDetails.phone
    );
  };

  const shouldShowLinkReport =
    parsedDetails.phone &&
    slotEvent.appointment !== "cancel" &&
    slotEvent.appointment !== "online" &&
    slotEvent.appointment !== "staffOnly" &&
    slotEvent.appointment !== "onlineCat" &&
    slotEvent.appointment !== "schedule" &&
    moment(slotEvent.start).isSame(moment(), "day");

  return (
    <Modal
      size={showTransfer ? "xl" : ""}
      modalClassName="modal-cambridge-calendar"
      isOpen={modalToEditEvent}
      toggle={toggleModalToEdit}
    >
      <ModalHeader toggle={toggleModalToEdit}>
        Confirm your event details for {props.groomer.toUpperCase()}
        {extraDetails && (
          <>
            <div className="overflow-x-auto mt-[10px]">
              <table className="min-w-full bg-white border rounded-xl border-[#ced4da]">
                <tbody>
                  <tr
                    className="cursor-copy"
                    onClick={() => {
                      copyToClipboard(
                        `${extraDetails.id} - ${extraDetails.phone}`
                      );
                    }}
                  >
                    <td className="text-sm font-normal border-b p-[5px] border-[#ced4da]">
                      Id - Primary phone number
                    </td>
                    <td className="text-sm font-normal border-b p-[5px] border-[#ced4da]">
                      <b>
                        {extraDetails.id} - {extraDetails.phone}
                      </b>
                    </td>
                  </tr>

                  {extraDetails.pets.length > 0 && (
                    <tr
                      className={`${
                        extraDetails.pets.length === 1 && "cursor-copy"
                      }`}
                      onClick={() => {
                        if (extraDetails.pets.length === 1) {
                          copyToClipboard(
                            extraDetails.pets &&
                              extraDetails.pets
                                .map((pet) => `${pet.name} (${pet.breed})`)
                                .join(", ")
                          );
                        }
                      }}
                    >
                      <td className="text-sm font-normal border-b p-[5px] border-[#ced4da]">
                        Pets
                      </td>
                      <td className="text-sm font-normal border-b p-[5px] border-[#ced4da]">
                        <b>
                          {extraDetails.pets &&
                            extraDetails.pets
                              .map((pet) => `${pet.name} (${pet.breed})`)
                              .join(", ")}
                        </b>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </>
        )}
      </ModalHeader>
      <ModalBody className="flex justify-evenly">
        {slotEvent &&
        slotEvent.title &&
        slotEvent.title.includes("Spots available for Daycare") ? (
          <form>
            Add or edit Daycare Spots for this day
            <br />
            <input
              value={numOfSpots}
              className="w-full p-2 my-2 border rounded-sm"
              placeholder="Number of spots"
              type="number"
              onChange={(e) => setNumOfSpots(e.target.value)}
              min={0}
            />
            <button
              onClick={(e) =>
                updateDaycareHandler(e, daycareSpotId, slotEvent.id)
              }
              className="w-full p-2 bg-orange-300 rounded-sm"
            >
              Submit
            </button>
          </form>
        ) : (
          <>
            {/* Left side of modal */}
            <div className="p-4 border shadow-md">
              <h5 className="py-2 text-center bg-yellow-200">
                Current Details {props.groomer.toUpperCase()}
              </h5>
              {!isDaycare && (
                <button
                  onClick={() => setShowTransfer(!showTransfer)}
                  className="w-full px-4 py-2 mb-2 text-white rounded-md bg-cyan-800"
                >
                  {showTransfer ? (
                    <span>
                      Cancel Transfer{" "}
                      <MdCancel size={30} className="inline-block ml-2" />
                    </span>
                  ) : (
                    <span>
                      Transfer appointment
                      <FaArrowsAltH size={30} className="inline-block ml-2" />
                    </span>
                  )}
                </button>
              )}

              {jobType !== "no restrictions" ? (
                <>
                  <Form onSubmit={onSubmitModalToEdit}>
                    {showTransfer && (
                      <button
                        onClick={copyAppointmentDetails}
                        className="w-full p-2 mb-4 text-white rounded-md bg-pink"
                      >
                        Copy appointment details{" "}
                        <FaCopy size={22} className="inline-block ml-2" />
                      </button>
                    )}

                    <FormGroup>
                      <Label>
                        Current App type code:{" "}
                        <b>
                          {typeof appointment === "object"
                            ? appointment.value
                            : appointment}
                        </b>{" "}
                      </Label>
                      {!isDaycare && (
                        <Select
                          name="form-field-name"
                          value={appointment}
                          options={
                            localStorage.getItem("USERNAME") ===
                            "adrianaReception"
                              ? optionsNoStaffOnly
                              : props.groomer === "paola"
                              ? optionsAdmin
                              : props.isTrainee
                              ? optionsTrainee
                              : slotEvent.appointment !== "schedule" ||
                                appointment !== "schedule"
                              ? optionsNoSchedule
                              : options
                          }
                          placeholder="Select one of the following:"
                          isSearchable={false}
                          onChange={(value) => {
                            return setAppointment(value);
                          }}
                        />
                      )}

                      <br />
                      {userGroomers.includes(jobType) ? (
                        slotEvent.appointment === "online" ||
                        slotEvent.appointment === "onlineBath" ||
                        slotEvent.appointment === "onlineCat" ||
                        slotEvent.appointment === "schedule" ? (
                          appointment === "schedule" ||
                          appointment.value === "schedule" ? (
                            <>
                              <Label>Custom Staff Event</Label>
                              <Input
                                type="text"
                                name="title"
                                id="title"
                                defaultValue={slotEvent.title}
                                placeholder="(Optional) Type your details or leave it empty"
                                onChange={(e) => {
                                  setTitle(e.target.value);
                                  if (e.target.value) {
                                    setTitleInputEmpty(false);
                                  }
                                }}
                                onKeyUp={(e) => {
                                  if (e.keyCode === 8) {
                                    setTitle(e.target.value);
                                  }
                                }}
                                className={titleInputEmpty ? "borderError" : ""}
                              />
                              <br />
                            </>
                          ) : (
                            <>
                              <Label>* Phone and other details</Label>
                              <Input
                                type="text"
                                name="title"
                                id="title"
                                defaultValue={
                                  title !== "" ? title : slotEvent.title
                                }
                                placeholder="Enter Phone and other details"
                                onChange={(e) => {
                                  setTitle(e.target.value);
                                  if (e.target.value) {
                                    setTitleInputEmpty(false);
                                  }
                                }}
                                onKeyUp={(e) => {
                                  if (e.keyCode === 8) {
                                    setTitle(e.target.value);
                                  }
                                }}
                                className={titleInputEmpty ? "borderError" : ""}
                              />
                              <br />
                              <Label>* Name and Breed</Label>
                              <Input
                                type="text"
                                name="breedName"
                                id="breedName"
                                defaultValue={
                                  breedName !== ""
                                    ? breedName
                                    : slotEvent.breedName
                                }
                                placeholder="*Enter Name and Breed"
                                onKeyUp={(e) => {
                                  if (e.keyCode === 8) {
                                    setBreedName(e.target.value);
                                  }
                                }}
                                onChange={(e) => {
                                  setBreedName(e.target.value);
                                  if (e.target.value) {
                                    setBreedInputEmpty(false);
                                  }
                                }}
                                className={breedInputEmpty ? "borderError" : ""}
                              />
                            </>
                          )
                        ) : (
                          <>
                            {(typeof appointment !== "object" &&
                              appointment !== "schedule") ||
                            appointment.value !== "schedule" ? (
                              <>
                                <p>
                                  {slotEvent.breedName
                                    ? "Breed and Name: "
                                    : "Event details: "}
                                  <b style={{ textTransform: "capitalize" }}>
                                    {slotEvent.breedName
                                      ? slotEvent.breedName
                                      : slotEvent.title}
                                  </b>
                                </p>
                                <Input
                                  type="text"
                                  name="breedName"
                                  id="breedName"
                                  defaultValue={slotEvent.breedName}
                                  placeholder="*Enter Name and Breed"
                                  onKeyUp={(e) => {
                                    if (e.keyCode === 8) {
                                      setBreedName(e.target.value);
                                    }
                                  }}
                                  onChange={(e) => {
                                    setBreedName(e.target.value);
                                    if (e.target.value) {
                                      setBreedInputEmpty(false);
                                    }
                                  }}
                                  className={
                                    breedInputEmpty ? "borderError" : ""
                                  }
                                />
                              </>
                            ) : (
                              <>
                                <Label>Custom Staff Event</Label>
                                <Input
                                  type="text"
                                  name="title"
                                  id="title"
                                  defaultValue={slotEvent.title}
                                  placeholder="(Optional) Type your details or leave it empty"
                                  onChange={(e) => {
                                    setTitle(e.target.value);
                                    if (e.target.value) {
                                      setTitleInputEmpty(false);
                                    }
                                  }}
                                  onKeyUp={(e) => {
                                    if (e.keyCode === 8) {
                                      setTitle(e.target.value);
                                    }
                                  }}
                                  className={
                                    titleInputEmpty ? "borderError" : ""
                                  }
                                />
                                <br />
                              </>
                            )}
                          </>
                        )
                      ) : appointment === "schedule" ||
                        appointment.value === "schedule" ? (
                        // Admin and receptionist can Edit Red events STARTS
                        <>
                          <Label>Custom Staff Event</Label>
                          <Input
                            type="text"
                            name="title"
                            id="title"
                            defaultValue={slotEvent.title}
                            placeholder="(Optional) Type your details or leave it empty"
                            onChange={(e) => {
                              setTitle(e.target.value);
                              if (e.target.value) {
                                setTitleInputEmpty(false);
                              }
                            }}
                            onKeyUp={(e) => {
                              if (e.keyCode === 8) {
                                setTitle(e.target.value);
                              }
                            }}
                            className={titleInputEmpty ? "borderError" : ""}
                          />
                          <br />
                        </>
                      ) : (
                        // Admin and receptionist can Edit events STARTS
                        <>
                          <Label>* Phone and other details</Label>
                          <Input
                            type="text"
                            name="title"
                            id="title"
                            defaultValue={slotEvent.title}
                            placeholder="Enter Phone and other details"
                            onChange={(e) => {
                              setTitle(e.target.value);
                              if (e.target.value) {
                                setTitleInputEmpty(false);
                              }
                            }}
                            onKeyUp={(e) => {
                              if (e.keyCode === 8) {
                                setTitle(e.target.value);
                              }
                            }}
                            className={titleInputEmpty ? "borderError" : ""}
                          />
                          <br />
                          <Label>* Name and Breed</Label>
                          <Input
                            type="text"
                            name="breedName"
                            id="breedName"
                            defaultValue={slotEvent.breedName}
                            placeholder="* Enter Name and Breed"
                            onKeyUp={(e) => {
                              if (e.keyCode === 8) {
                                setBreedName(e.target.value);
                              }
                            }}
                            onChange={(e) => {
                              setBreedName(e.target.value);
                              if (e.target.value) {
                                setBreedInputEmpty(false);
                              }
                            }}
                            className={breedInputEmpty ? "borderError" : ""}
                          />
                        </>
                        // Admin and receptionist ENDS
                      )}

                      <p className="mb-0 text-left time-on-modal">
                        Appointment:{" "}
                        <b>
                          {moment(start)
                            .tz("America/Toronto")
                            .format("dddd, MMMM Do YYYY, h:mm a")}
                        </b>
                      </p>

                      {shouldShowLinkReport ? (
                        !commissionExists() ? (
                          <Link
                            to="/auth/commission"
                            state={{
                              groomer:
                                slotEvent.name === "bather"
                                  ? "baths"
                                  : slotEvent.name,
                              nameBreed: slotEvent.breedName,
                              clientId: parsedDetails.clientId,
                              clientPhone: parsedDetails.phone,
                              arrivalTime: moment(start)
                                .tz("America/Toronto")
                                .format("h:mm"),
                            }}
                            className="hover:no-underline my-[10px] btn btn-block btn-primary"
                          >
                            💰 Create Daily Report
                          </Link>
                        ) : (
                          <button
                            className="my-[10px] btn btn-block btn-primary"
                            disabled
                          >
                            💰 Report created
                          </button>
                        )
                      ) : null}

                      <p className="text-left">
                        Last change made by: <strong>{lastModifiedBy}</strong>
                      </p>
                      <button
                        onClick={toggleHistoryHandler}
                        className="btn btn-dark btn-block"
                      >
                        {eventHistoryToggle ? "Hide" : "Show"} Change history
                      </button>
                      {eventHistoryToggle
                        ? appointmentModifiers.length
                          ? appointmentModifiers.map((value) => {
                              return (
                                <p
                                  key={value.id}
                                  className="event-history-wrapper"
                                >
                                  <i className="fas fa-user-edit"></i>{" "}
                                  {value.name}{" "}
                                  <i className="fas fa-pencil-alt"></i>{" "}
                                  {moment(value.date)
                                    .tz("America/Toronto")
                                    .format("YYYY/MM/DD h:mm a")}
                                </p>
                              );
                            })
                          : null
                        : null}
                      {loadingAxiosReq ? (
                        <LoadPage />
                      ) : (
                        <Button
                          color="info"
                          style={{ marginTop: "1rem" }}
                          block
                        >
                          Submit Event
                        </Button>
                      )}
                    </FormGroup>
                  </Form>

                  {localStorage.getItem("USERNAME") !== "adrianaReception" && (
                    <Button
                      onClick={() => {
                        handleDeleteEvent(slotEvent.id, slotEvent);
                      }}
                      color="danger"
                      style={{ marginTop: "1rem" }}
                      block
                    >
                      Delete Event
                    </Button>
                  )}
                </>
              ) : (
                <div>
                  <p className="text-left time-on-modal">
                    Appointment:{" "}
                    <b>{moment(start).format("dddd, MMMM Do YYYY, h:mm a")}</b>
                  </p>
                  <p>
                    Name and Breed:{" "}
                    <b>
                      {slotEvent.breedName && slotEvent.breedName.toUpperCase()}
                    </b>
                  </p>
                </div>
              )}
            </div>

            {/* Right side of modal CALENDAR */}
            {showTransfer && (
              <div
                id="Emp3Calendar"
                className="px-2 pt-4 border shadow-md col-xl-6 col-md-6 col-xs-12"
              >
                <h5 className="py-2 text-center bg-yellow-200">Transfer to</h5>
                {appointmentDetailsObj ? (
                  <div>
                    <Select
                      className="mb-2"
                      placeholder="Switch Groomer"
                      onChange={(value) => {
                        setNewGroomer(value.value);
                        setTriggerFetchEvents(true);
                        setTimeout(() => {
                          setTriggerFetchEvents(false);
                        }, 1000);
                      }}
                      options={
                        location.pathname.includes("cambridge")
                          ? optionsGroomersCambridge
                          : optionsGroomers
                      }
                    />
                    <MainCalendar
                      isFromEditClients={true}
                      triggerFetchEvents={triggerFetchEvents}
                      setTriggerFetchEvents={setTriggerFetchEvents}
                      date={start}
                      isBather={false}
                      title={title}
                      breedName={breedName}
                      city={localStorage.getItem("CITY") || "milton"}
                      groomer={
                        !newGroomer.toLowerCase()
                          ? props.groomer
                          : newGroomer.toLowerCase()
                      }
                      step={15}
                      timeSlots={4}
                      min={new Date(2019, 10, 0, 7, 0, 0)}
                      max={new Date(2019, 10, 0, 15, 0, 0)}
                      customHeight={800}
                      appointmentDetailsObj={appointmentDetailsObj}
                      standardControls
                    />
                  </div>
                ) : (
                  <div className="mt-4 text-center">
                    <FaLongArrowAltLeft size={30} className="inline-block" />{" "}
                    Copy the appointment details first
                  </div>
                )}
              </div>
            )}
          </>
        )}
      </ModalBody>
    </Modal>
  );
}
