import { useState, useEffect } from "react"
import { Label, Button, Form, FormGroup, Input } from "reactstrap"
import Select from "react-select"
import LoadPage from "../LoadingPage"
import axios from "axios"
import API from "../../utils/API"

export default function CommentUpdateForm({
	commentToBeEdited,
	setCommentToBeEdited,
	optionsGroomers,
	fetchData,
}) {
	const [date, setDate] = useState("")
	const [groomer, setGroomer] = useState("")
	const [comment, setComment] = useState("")
	const [loadingAxiosReq, setLoadingAxiosReq] = useState("")

	useEffect(() => {
		if (commentToBeEdited) {
			setDate(commentToBeEdited.date)
			setGroomer(commentToBeEdited.groomer)
			setComment(commentToBeEdited.comment)
		}
	}, [])

	const handleEditCommentSubmit = async () => {
		const id = commentToBeEdited.id
		let commentObj = {
			date,
			comment,
			groomer,
		}
		setLoadingAxiosReq(true)
		await API.updateComment(id, commentObj)
			.then(() => {
				setTimeout(() => {
					fetchData()
					setCommentToBeEdited(null)
					setLoadingAxiosReq(false)
				}, 1000)
			})
			.catch((error) => console.log(error))
	}

	return (
		<Form
			className={`${
				commentToBeEdited &&
				commentToBeEdited.groomer &&
				commentToBeEdited.groomer.toLowerCase() === "paola"
					? "bg-white"
					: commentToBeEdited.groomer.toLowerCase() === "claudia"
					? "bg-[#b0eeff]"
					: commentToBeEdited.groomer.toLowerCase() === "diana"
					? "bg-[#fcdcf9]"
					: commentToBeEdited.groomer.toLowerCase() === "adriana"
					? "bg-[#fa555a]"
					: commentToBeEdited.groomer.toLowerCase() === "peppa"
					? "bg-[#fc9e56]"
					: commentToBeEdited.groomer.toLowerCase() === "mercedes"
					? "bg[#f0ceb4]"
					: commentToBeEdited.groomer.toLowerCase() === "baths"
					? "bg-[#a2e8cc]"
					: ""
			} rounded-md px-4 py-2`}
		>
			<FormGroup>
				<h4 className="text-left text-md">Edit comment</h4>
				<div className="row">
					<div className="col-xl-3 col-md-3">
						<Label>Date Format: YYYY/MM/DD</Label>
						<Input
							onChange={(e) => setDate(e.target.value)}
							type="date"
							name="date"
							defaultValue={date}
						/>
					</div>
					<div className="col-xl-7 col-md-5 commentSectionAddComment">
						<Label>Comments</Label>
						<Input
							placeholder="Enter Notes"
							onChange={(e) => setComment(e.target.value)}
							type="text"
							name="comment"
							defaultValue={comment}
						></Input>
					</div>
					<div className="absolute right-5 top-24">
						<Select
							name="form-field-name"
							options={optionsGroomers}
							placeholder="Groomer:"
							isSearchable={false}
							onChange={(value) => setGroomer(value.value)}
							defaultValue={{
								value: commentToBeEdited.groomer,
								label: commentToBeEdited.groomer,
							}}
						/>
					</div>
				</div>
				{loadingAxiosReq ? (
					<LoadPage />
				) : (
					<Button
						onClick={handleEditCommentSubmit}
						block
						className="addCommentBtn mt-2"
						color="warning"
					>
						Edit Comment
					</Button>
				)}
			</FormGroup>
		</Form>
	)
}
