import React, { useState } from "react"
import {
	Button,
	Modal,
	ModalBody,
	ModalHeader,
	Form,
	FormGroup,
	Label,
	Col,
	Input,
} from "reactstrap"
import LoadPage from "../../components/LoadingPage"

const ModalToAdd = (props) => {
	const [daycarePayment, setDaycarePayment] = useState("")
	return (
		<Modal
			modalClassName="modal-add-clients"
			isOpen={props.openModal}
			toggle={props.toggleModalToEditCommission}
		>
			<ModalHeader toggle={props.toggleModalToEditCommission}>
				Edit{" "}
				<strong>
					{props.groomerName && String(props.groomerName).toUpperCase()}
				</strong>{" "}
				info
			</ModalHeader>
			<ModalBody>
				<Form onSubmit={props.submitFormAll} className="form-edit-commission">
					<FormGroup row>
						<Label className=" text-align-center" md={12}>
							<i className="fas fa-calendar"></i> Date: {props.valueDate}
						</Label>
					</FormGroup>
					<FormGroup row>
						<Label className=" text-align-center" md={4}>
							<i className="fas fa-user-circle"></i> Client #
						</Label>
						<Col lg={8}>
							<Input
								className="form-commission"
								defaultValue={props.valueClientNumber}
								name="clientNumber"
								onChange={props.handleChange}
								placeholder="Client #"
								type="text"
							/>
						</Col>
					</FormGroup>
					<FormGroup row>
						<Label className=" text-align-center" md={4}>
							<i className="fas fa-user-circle"></i> Phone #
						</Label>
						<Col lg={8}>
							<Input
								className="form-commission"
								defaultValue={props.valueClientPhone}
								name="clientPhone"
								onChange={props.handleChange}
								placeholder="Phone #"
								type="text"
							/>
						</Col>
					</FormGroup>
					<FormGroup row>
						<Label className=" text-align-center" md={4}>
							<i className="fas fa-paw"></i> Name-breed
						</Label>
						<Col lg={8}>
							<Input
								className="form-commission"
								defaultValue={props.valueNameBreed}
								name="nameBreed"
								onChange={props.handleChange}
								placeholder="Name - Breed"
								type="text"
							/>
						</Col>
					</FormGroup>
					<FormGroup row>
						<Label className=" text-align-center" md={4}>
							<i className="far fa-clock"></i> Arrival time
						</Label>
						<Col lg={8}>
							<Input
								className="form-commission"
								defaultValue={props.valueArrivalTime}
								name="arrivalTime"
								onChange={props.handleChange}
								placeholder="Arrival Time"
								type="text"
							/>
						</Col>
					</FormGroup>
					<FormGroup row>
						<Label className=" text-align-center" md={4}>
							<i className="fas fa-home"></i> Pickup time
						</Label>
						<Col lg={8}>
							<Input
								className="form-commission"
								defaultValue={props.valuePickupTime}
								name="pickupTime"
								onChange={props.handleChange}
								placeholder="Pickup Time"
								type="text"
							/>
						</Col>
					</FormGroup>
					{props.groomerName === "daycare" ? (
						<div className="border-2 p-2">
							<h5>
								Method of Payment: (functionality not implemented yet for
								Membership, use In Person mode for now)
							</h5>
							<div className="flex py-2 gap-2 flex-row w-full justify-between">
								<button
									onClick={(e) => {
										e.preventDefault()
										setDaycarePayment("membership")
									}}
									className={`bg-blue-900 py-2 text-white rounded-md w-1/2 ${
										daycarePayment === "membership"
											? "border-4 border-white"
											: ""
									}`}
								>
									Membership
								</button>
								<button
									onClick={(e) => {
										e.preventDefault()
										setDaycarePayment("in-person")
									}}
									className={`bg-blue-900 py-2 text-white rounded-md w-1/2 ${
										daycarePayment === "in-person"
											? "border-4 border-white"
											: ""
									}`}
								>
									In Person
								</button>
							</div>
							{daycarePayment === "in-person" ? (
								<FormGroup row>
									<Label className=" text-align-center" md={4}>
										<i className="fas fa-dollar-sign"></i> Cost (No taxes)
									</Label>
									<Col lg={8}>
										<Input
											className="form-commission "
											defaultValue={props.valueCost}
											name="cost"
											onChange={props.handleChange}
											placeholder="Cost"
											type="text"
										/>
									</Col>
								</FormGroup>
							) : (
								<button className="btn w-full btn-warning">
									Confirm payment with Membership
								</button>
							)}
						</div>
					) : (
						<FormGroup row>
							<Label className=" text-align-center" md={4}>
								<i className="fas fa-dollar-sign"></i> Cost (No taxes)
							</Label>
							<Col lg={8}>
								<Input
									className="form-commission "
									defaultValue={props.valueCost}
									name="cost"
									onChange={props.handleChange}
									placeholder="Cost"
									type="text"
								/>
							</Col>
						</FormGroup>
					)}
					<FormGroup row>
						<Label className=" text-align-center" md={4}>
							<i className="fas fa-coins"></i> Tip (Including Cents)
						</Label>
						<Col lg={8}>
							<Input
								className="form-commission"
								defaultValue={props.valueTip}
								name="tip"
								onChange={props.handleChange}
								placeholder="Tip"
								type="text"
							/>
						</Col>
					</FormGroup>
					{props.loadingAxiosReq ? (
						<LoadPage />
					) : (
						<Button className="addPet-btn" block color="warning">
							Submit changes
						</Button>
					)}
					<p className="error-message-registration text-align-center margin-top-small">
						{props.errMsg}
					</p>
				</Form>
			</ModalBody>
		</Modal>
	)
}

export default ModalToAdd
