import { useContext } from "react"
import { RegistrationContext } from "../../../contexts/registrationStatusContext"
import { Modal, ModalBody, ModalHeader } from "reactstrap"
import DaycareBookingForm from "../../DaycareBookingForm"

export default function Index() {
	const { showBookTrialDayModal, setShowBookTrialDayModal } =
		useContext(RegistrationContext)
	return (
		<Modal
			isOpen={showBookTrialDayModal}
			toggle={() => setShowBookTrialDayModal(!showBookTrialDayModal)}
		>
			<ModalHeader
				toggle={() => setShowBookTrialDayModal(!showBookTrialDayModal)}
			></ModalHeader>
			<ModalBody>
				<DaycareBookingForm appointmentType="free_trial" />
			</ModalBody>
		</Modal>
	)
}
