import { Modal, ModalBody, ModalHeader } from "reactstrap"
import { useNavigate } from "react-router-dom"
import { useState, useContext, useEffect } from "react"
import { RegistrationContext } from "../../../contexts/registrationStatusContext"
import { FaScissors } from "react-icons/fa6"
import { GiTennisBall } from "react-icons/gi"
import axios from "axios"
import City from "../../BookingForm/FrmScreens/City"
import Tag from "../../BookingForm/Tag"

export default function Index({ isModalOpen, toggleModal, modalType }) {
	const navigate = useNavigate()
	const [selectedCity, setSelectedCity] = useState("")
	const [emailOrPhone, setEmailOrPhone] = useState("")
	const [accountFound, setAccountFound] = useState("")
	const [showAccountFinderForm, setShowAccountFinderForm] = useState(false)
	const [dogHasBeenInTrialSession, setDogHasBeenInTrialSession] =
		useState(undefined)

	useEffect(() => {
		if (!isModalOpen) {
			setAccDetailsForDaycare(null)
			setAccountFound(null)
		}
	}, [isModalOpen])

	const {
		setAccDetailsForDaycare,
		setIsRegistrationInProcess,
		setShowRegisterDaycareModal,
		setShowRegisterGroomingModal,
		setShowLoginModal,
		setShowBookTrialDayModal,
		setShowBookDaycareModal,
	} = useContext(RegistrationContext)

	const findAccountHandler = async (e) => {
		e.preventDefault()
		if (!emailOrPhone) {
			return
		}
		try {
			const CITY = localStorage.getItem("CITY")
			const res = await axios.get(
				`/search/clients/daycare/${CITY}/${emailOrPhone}`
			)
			if (res.data) {
				if (
					res.data.isPasswordTemporary === null ||
					res.data.isPasswordTemporary === true
				) {
					localStorage.setItem("FOUND_ACCOUNT", JSON.stringify(res.data))
					setIsRegistrationInProcess(true)
					setAccDetailsForDaycare(res.data)
					setAccountFound(res.data)
				} else {
					alert(
						`Your account has already been created. \nCheck your email for more details. \nUse your credentials to login or use the forgot password option to reset your account.\nFrom your account, you can add additional pets if that's what you are trying to achieve.`
					)
				}
			} else {
				alert("No account found, try a different email or phone")
			}
		} catch (err) {
			console.error(err.response)
		}
	}

	return (
		<Modal isOpen={isModalOpen} toggle={() => toggleModal(!isModalOpen)}>
			<ModalHeader toggle={() => toggleModal(!isModalOpen)}></ModalHeader>
			<ModalBody className="">
				<div className="relative">
					{showAccountFinderForm && (
						<span onClick={() => setShowAccountFinderForm(false)}>
							<i className="cursor-pointer fas fa-long-arrow-alt-left"></i> Back
						</span>
					)}

					<h3
						className={`${
							modalType === "grooming" ? "mb-4" : ""
						} text-center font-bold sm:text-4xl text-2xl mt-0 bg-lightBlue p-[10px] rounded-[0.3rem]`}
					>
						{modalType === "daycare" ? "Doggie Daycare" : "Grooming"}{" "}
						Registration
					</h3>

					{modalType === "daycare" && (
						<div className="my-4">
							<div className="text-lg text-center">
								<h4>
									Has your dog been in one of our <br /> free trial sessions
									before?
								</h4>
							</div>
							<div className="flex flex-row gap-2 mt-4">
								<button
									onClick={() => setDogHasBeenInTrialSession(true)}
									className={`bg-main p-2 w-1/2 rounded-md text-black ${
										dogHasBeenInTrialSession ? "scale-[0.98]" : "opacity-50"
									}`}
								>
									YES
								</button>
								<button
									onClick={() => setDogHasBeenInTrialSession(false)}
									className={`bg-pink p-2 w-1/2 rounded-md text-black ${
										!dogHasBeenInTrialSession ? "scale-[0.98]" : "opacity-50"
									}`}
								>
									NO
								</button>
							</div>
						</div>
					)}

					{modalType === "daycare" && !dogHasBeenInTrialSession ? (
						<>
							<hr />
							<div className="mt-2">
								We require that your dogs attend a free trial session first.{" "}
								<br />
								This, will ensure the daycare environment is the right fit for
								them.
							</div>
							<div className="text-center">
								<i className="my-2 text-2xl fas fa-long-arrow-alt-down"></i>
							</div>

							<button
								onClick={() => {
									toggleModal(false)
									setShowBookTrialDayModal(true)
								}}
								className="w-full text-white bg-main py-[10px] rounded-default"
							>
								Initiate free trial session booking
							</button>
						</>
					) : (
						<>
							<Tag
								onClick={() => setSelectedCity("")}
								hasIcon
								value={selectedCity === "cambridge" ? "Cambridge" : "Milton"}
							/>
							{modalType === "daycare" ? (
								<>
									<div className="mb-4 text-center">
										<GiTennisBall
											color="#07f75b"
											size={30}
											className="inline-block"
										/>
									</div>
								</>
							) : (
								<div className="mb-4 text-center">
									<FaScissors
										color="#f7b307"
										size={30}
										className="inline-block"
									/>
								</div>
							)}

							{accountFound ? (
								<div>
									<h3 className="text-lg text-center">
										Yayy! Your Account was found <br />
										<span className={`my-2 text-center text-green-400`}>
											<i className="fas fa-paw"></i>{" "}
										</span>
									</h3>
									<h3 className="text-lg text-center">
										Click the button to finish the process for:
										{accountFound.Pets &&
											accountFound.Pets.map((pet, index) => (
												<span className="ml-2" key={index}>
													<strong className="capitalize">
														{pet.name}
														{index < accountFound.Pets.length - 1 ? ", " : ""}
													</strong>
												</span>
											))}
										{accountFound.PetCambridges &&
											accountFound.PetCambridges.map((pet, index) => (
												<span className="ml-2" key={index}>
													<strong className="capitalize">
														{pet.name}
														{index < accountFound.PetCambridges.length - 1
															? ", "
															: ""}
													</strong>
												</span>
											))}
									</h3>
									<button
										onClick={() => {
											if (modalType === "daycare") {
												setShowRegisterDaycareModal(false)
												setShowLoginModal(false)
												navigate("/daycare_registration")
											} else {
												setShowRegisterGroomingModal(false)
												setShowLoginModal(false)
												navigate("/grooming_registration")
											}
										}}
										className="btn btn-info btn-block"
									>
										Continue
									</button>
								</div>
							) : showAccountFinderForm ? (
								<>
									<hr />
									<h3 className="text-lg text-center">
										If you have previously used our Grooming Services, you
										already have an account with us... <br />
										Let's find it <br />
										<i className="fas fa-long-arrow-alt-down"></i>
									</h3>
									<form onSubmit={findAccountHandler} className="">
										<div className="">
											<input
												value={emailOrPhone}
												onChange={(e) => setEmailOrPhone(e.target.value)}
												type="text"
												placeholder="Enter your email or phone number"
												className="w-full p-2 border rounded-md"
											/>
										</div>
										<div className="mt-4">
											<button
												className="w-full px-4 py-2 text-white bg-main rounded-default"
												type="submit"
											>
												Submit
											</button>
										</div>
									</form>
								</>
							) : !selectedCity ? (
								<>
									<City
										onClickCambridge={() => {
											if (modalType === "daycare") {
												alert(
													"This location is unavailable at the moment for Daycare"
												)
											} else {
												setSelectedCity("cambridge")
											}
										}}
										onClickMilton={() => setSelectedCity("milton")}
									/>
								</>
							) : (
								<div className="flex flex-col justify-between gap-3 md:flex-row">
									<div
										onClick={() => {
											if (selectedCity === "milton") {
												localStorage.setItem("CITY", "milton")
											} else {
												localStorage.setItem("CITY", "cambridge")
											}
											setShowAccountFinderForm(!showAccountFinderForm)
										}}
										className="flex flex-col items-center cursor-pointer card hover:scale-[1.02] p-[5px]"
									>
										<img
											className="w-[60%]"
											src="/images/shihtzu_face.png"
											alt="dog 2"
										/>
										<i className="mt-4 text-lg fas fa-long-arrow-alt-down"></i>
										<button className="flex-grow w-full px-4 py-2 text-2xl text-white rounded-sm bg-pink md:mt-0">
											Existing Grooming Clients
										</button>
									</div>
									<div
										onClick={() => {
											localStorage.removeItem("FOUND_ACCOUNT")
											if (modalType === "daycare") {
												if (selectedCity === "milton") {
													localStorage.setItem("CITY", "milton")
												} else {
													localStorage.setItem("CITY", "cambridge")
												}
												setShowRegisterDaycareModal(false)
												setShowLoginModal(false)
												navigate("/daycare_registration")
											} else {
												if (selectedCity === "milton") {
													localStorage.setItem("CITY", "milton")
												} else {
													localStorage.setItem("CITY", "cambridge")
												}
												setShowRegisterGroomingModal(false)
												setShowLoginModal(false)
												navigate("/grooming_registration")
											}
										}}
										className="flex flex-col items-center cursor-pointer card p-[5px]"
									>
										<img
											className="w-[60%]"
											src="/images/golden_retriever_face.png"
											alt="dog 1"
										/>
										<i className="mt-4 text-lg fas fa-long-arrow-alt-down"></i>
										<button className="flex-grow w-full px-4 py-2 mt-4 mb-2 text-2xl text-white bg-pink rounded-sm hover:bg-pink-500 md:mt-0">
											New Clients
										</button>
									</div>
								</div>
							)}
						</>
					)}
				</div>
			</ModalBody>
		</Modal>
	)
}
