import { useEffect } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  FormGroup,
  Input,
  Label,
} from "reactstrap";
import LoadPage from "../../LoadingPage";
import moment from "moment";
import Select from "react-select";

export default function Index({
  modalToAddEvent,
  setModalToAddEvent,
  props,
  onSubmitModal,
  appointment,
  setAppointment,
  optionsAdmin,
  optionsTrainee,
  options,
  slotEvent,
  setTitle,
  title,
  titleInputEmpty,
  setTitleInputEmpty,
  setBreedName,
  breedName,
  setBreedInputEmpty,
  breedInputEmpty,
  loadingAxiosReq,
  errorOnModalCalendar,
  appointmentDetailsObj,
  availableSpotsDate,
  numOfSpots,
  setNumOfSpots,
  addDaycareHandler,
  extraDetails,
}) {
  useEffect(() => {
    if (appointmentDetailsObj) {
      setTitle(appointmentDetailsObj.title);
      setTitleInputEmpty(false);
    }
  }, [appointmentDetailsObj]);

  const pasteAppointmentDetailsHandler = (e) => {
    e.preventDefault();
    setTitle(appointmentDetailsObj.title);
    setTitleInputEmpty(false);
    setBreedName(appointmentDetailsObj.breedName);
    setBreedInputEmpty(false);
    setAppointment({
      value: appointmentDetailsObj.appointment,
      label: appointmentDetailsObj.appointment,
    });
  };

  const afternoonThreshold = moment("13:29:00", "HH:mm:ss");

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      alert("Copied to clipboard!");
    });
  };

  return (
    <Modal
      modalClassName="modal-cambridge-calendar"
      isOpen={modalToAddEvent}
      toggle={() => {
        setModalToAddEvent(!modalToAddEvent);
        if (!modalToAddEvent) {
          setAppointment("");
          setTitle("");
          setBreedName("");
        }
      }}
    >
      <ModalHeader
        toggle={() => {
          if (!modalToAddEvent) {
            setAppointment("");
          }
          setModalToAddEvent(!modalToAddEvent);
        }}
      >
        Set The event type on the Calendar for {props.groomer.toUpperCase()}
        {extraDetails && (
          <>
            <div className="overflow-x-auto mt-[10px]">
              <table className="min-w-full bg-white border rounded-xl border-[#ced4da]">
                <tbody>
                  <tr
                    className="cursor-copy"
                    onClick={() => {
                      copyToClipboard(
                        `${extraDetails.id} - ${extraDetails.phone}`
                      );
                    }}
                  >
                    <td className="text-sm font-normal border-b p-[5px] border-[#ced4da]">
                      Id - Primary phone number
                    </td>
                    <td className="text-sm font-normal border-b p-[5px] border-[#ced4da]">
                      <b>
                        {extraDetails.id} - {extraDetails.phone}
                      </b>
                    </td>
                  </tr>

                  {extraDetails.pets.length > 0 && (
                    <tr
                      className={`${
                        extraDetails.pets.length === 1 && "cursor-copy"
                      }`}
                      onClick={() => {
                        if (extraDetails.pets.length === 1) {
                          copyToClipboard(
                            extraDetails.pets &&
                              extraDetails.pets
                                .map((pet) => `${pet.name} (${pet.breed})`)
                                .join(", ")
                          );
                        }
                      }}
                    >
                      <td className="text-sm font-normal border-b p-[5px] border-[#ced4da]">
                        Pets
                      </td>
                      <td className="text-sm font-normal border-b p-[5px] border-[#ced4da]">
                        <b>
                          {extraDetails.pets &&
                            extraDetails.pets
                              .map((pet) => `${pet.name} (${pet.breed})`)
                              .join(", ")}
                        </b>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </>
        )}
      </ModalHeader>
      <ModalBody>
        {availableSpotsDate ? (
          <form onSubmit={addDaycareHandler}>
            No Daycare is available for this day, do you want to add spots?
            <br />
            <input
              value={numOfSpots}
              className="w-full p-2 my-2 border rounded-sm"
              placeholder="Number of spots"
              type="number"
              min={0}
              onChange={(e) => setNumOfSpots(e.target.value)}
            />
            <button className="w-full p-2 bg-orange-300 rounded-sm">
              Enable Daycare
            </button>
          </form>
        ) : (
          <>
            <Form onSubmit={onSubmitModal}>
              {appointmentDetailsObj && (
                <button
                  onClick={pasteAppointmentDetailsHandler}
                  className="w-full p-2 my-4 text-white bg-purple-600 rounded-md"
                >
                  Paste appointment
                </button>
              )}
              <FormGroup>
                {props.groomer === "daycare" ? (
                  moment(slotEvent.start).hour() < afternoonThreshold.hour() ? (
                    <span className="inline-block my-2 font-bold">
                      Morning Session
                    </span>
                  ) : (
                    <span className="inline-block my-2 font-bold">
                      Afternoon Session
                    </span>
                  )
                ) : (
                  <Select
                    value={appointment}
                    name="form-field-name"
                    options={
                      props.groomer === "paola"
                        ? optionsAdmin
                        : props.isTrainee
                        ? optionsTrainee
                        : options
                    }
                    placeholder="Select one of the following:"
                    isSearchable={false}
                    onChange={(value) => setAppointment(value)}
                  />
                )}

                <br />
                {(appointment.value !== "online" ||
                  appointment.value !== "onlineBath" ||
                  appointment.value !== "onlineCat" ||
                  appointment.value !== "staffOnly") &&
                (slotEvent.appointment === "schedule" ||
                  slotEvent.appointment === "staffOnly") ? (
                  <>
                    <Label>Custom Staff Event</Label>
                    <Input
                      type="text"
                      name="title"
                      id="title"
                      value={title}
                      placeholder="Type your details or leave it empty"
                      onChange={(e) => {
                        setTitle(e.target.value);
                        if (e.target.value) {
                          setTitleInputEmpty(false);
                        }
                      }}
                      onKeyUp={(e) => {
                        if (e.keyCode === 8) {
                          setTitle(e.target.value);
                        }
                      }}
                      className={titleInputEmpty ? "borderError" : ""}
                    />
                    <br />
                  </>
                ) : appointment.value === "schedule" ||
                  appointment.value === "staffOnly" ? (
                  <>
                    <Label>Custom Staff Event</Label>
                    <Input
                      type="text"
                      name="title"
                      id="title"
                      value={title}
                      placeholder="(Optional) Type your details or leave it empty"
                      onChange={(e) => {
                        setTitle(e.target.value);
                        if (e.target.value) {
                          setTitleInputEmpty(false);
                        }
                      }}
                      onKeyUp={(e) => {
                        if (e.keyCode === 8) {
                          setTitle(e.target.value);
                        }
                      }}
                      className={titleInputEmpty ? "borderError" : ""}
                    />
                    <br />
                  </>
                ) : (
                  <>
                    <Input
                      type="text"
                      name="title"
                      id="title"
                      value={title}
                      placeholder="*Please enter Phone and other details"
                      onChange={(e) => {
                        setTitle(e.target.value);
                        if (e.target.value || !slotEvent.title) {
                          setTitleInputEmpty(false);
                        }
                      }}
                      onKeyUp={(e) => {
                        if (e.keyCode === 8) {
                          setTitle(e.target.value);
                        }
                      }}
                      className={titleInputEmpty ? "borderError" : ""}
                    />
                    <br />
                    <Input
                      type="text"
                      name="breedName"
                      id="breedName"
                      value={breedName}
                      placeholder="* Enter Name and Breed"
                      onKeyUp={(e) => {
                        if (e.keyCode === 8) {
                          setBreedName(e.target.value);
                        }
                      }}
                      onChange={(e) => {
                        setBreedName(e.target.value);
                        if (e.target.value || !slotEvent.breedName) {
                          setBreedInputEmpty(false);
                        }
                      }}
                      className={breedInputEmpty ? "borderError" : ""}
                    />
                  </>
                )}

                <p className="time-on-modal">
                  Starts:{" "}
                  {moment(slotEvent.start).format("dddd, MMMM Do YYYY, h:mm a")}
                </p>
                <p className="time-on-modal">
                  Ends:{" "}
                  {moment(slotEvent.end).format("dddd, MMMM Do YYYY, h:mm a")}
                </p>

                <p
                  style={{
                    color: "red",
                    textAlign: "center",
                    marginTop: "10px",
                  }}
                >
                  {errorOnModalCalendar}
                </p>
                {loadingAxiosReq ? (
                  <LoadPage />
                ) : (
                  <Button color="info" style={{ marginTop: "1rem" }} block>
                    Submit Event
                  </Button>
                )}
              </FormGroup>
            </Form>
          </>
        )}
      </ModalBody>
    </Modal>
  );
}
