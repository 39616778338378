import BootstrapTable from "react-bootstrap-table-next"
import API from "../../utils/API"
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css"
import { Tooltip } from "react-tooltip"

export default function CommentsTable({
	commentsData,
	setOpenGroomingComments,
	showUpdateForm,
	setShowUpdateForm,
	setCommentToBeEdited,
	fetchData,
}) {
	const deleteComment = async (id) => {
		if (
			window.confirm(
				"Are you sure you want to delete this comment? This action cannot be reversed."
			)
		) {
			await API.deleteComment(id)
				.then(() => fetchData())
				.catch((err) => console.log(err))
		}
	}

	const cellWidth = (defaultValue, newValue) => {
		let columnWidth
		if (window.screen.width < 991) {
			columnWidth = { width: newValue }
		} else {
			columnWidth = { width: defaultValue }
		}
		return columnWidth
	}

	const columns = [
		{
			dataField: "date",
			text: "Date",
			sort: true,
			headerStyle: cellWidth("15%", "18%"),
			style: cellWidth("15%", "18%"),
		},
		{
			dataField: "comment",
			text: "Comment",
			sort: false,
			style: {
				whiteSpace: "wrap",
			},
			formatter: (cell, row) => (
				<div
					data-tooltip-id={row.id}
					data-tooltip-content={row.comment}
					data-for={`tooltip-${row.id}`}
					data-tooltip-place="left"
				>
					<Tooltip className="block sm:hidden" id={`${row.id}`} />
					{row.comment}
				</div>
			),
		},
		{
			dataField: "groomer",
			text: "Groomer",
			sort: true,
			headerStyle: cellWidth("15%", "24%"),
			style: cellWidth("15%", "24%"),
		},
		{
			dataField: "actions",
			text: "Actions",
			isDummyField: true,
			headerStyle: cellWidth("22%", "30%"),
			style: cellWidth("22%", "30%"),
			csvExport: false,
			formatter: (cell, row) => {
				return (
					<div className="flex flex-col lg:flex-row sm:items-center">
						<button
							className="btn btn-info sm:w-full"
							value={commentsData.id}
							onClick={() => setCommentToBeEdited(row)}
						>
							Update
						</button>
						<button
							className="btn btn-danger sm:w-full"
							value={row.id}
							onClick={() => deleteComment(row.id)}
						>
							Delete
						</button>
					</div>
				)
			},
		},
	]

	const rowStyle = (row, rowIndex) => {
		const style = {}
		if (row.groomer) {
			if (row.groomer.toLowerCase() === "paola") {
				style.backgroundColor = "white"
			}
			if (row.groomer.toLowerCase() === "claudia") {
				style.backgroundColor = "#b0eeff"
			}
			if (row.groomer.toLowerCase() === "diana") {
				style.backgroundColor = "#fcdcf9"
			}
			if (row.groomer.toLowerCase() === "frank") {
				style.backgroundColor = "#fcf803"
			}
			if (row.groomer.toLowerCase() === "adriana") {
				style.backgroundColor = "#fa555a"
				style.color = "white"
			}
			if (row.groomer.toLowerCase() === "peppa") {
				style.backgroundColor = "#fc9e56"
				style.color = "white"
			}
			if (row.groomer.toLowerCase() === "mercedes") {
				style.backgroundColor = "#f0ceb4"
			}
			if (row.groomer.toLowerCase() === "baths") {
				style.backgroundColor = "#a2e8cc"
			}
		}
		return style
	}

	return (
		<div className="table-responsive">
			<BootstrapTable
				className="react-table-component"
				keyField="id"
				data={commentsData}
				columns={columns}
				striped
				rowStyle={rowStyle}
				defaultSorted={[
					{
						dataField: "date",
						order: "desc",
						sortFunc: (a, b, order, dataField) => {
							const dateA = new Date(a)
							const dateB = new Date(b)
							return order === "desc" ? dateA - dateB : dateB - dateA
						},
					},
				]}
			/>
		</div>
	)
}
