import React from "react"
import moment from "moment"
import { useParams } from "react-router-dom"
import axios from "axios"

/**
 * Function returning the build date(as per provided epoch)
 * @param epoch Time in milliseconds
 */
export const getBuildDate = (epoch) => {
	const buildDate = moment(epoch).format("DD-MM-YYYY HH:MM")
	return buildDate
}

export function withRouter(Children) {
	return (props) => {
		const match = { params: useParams() }
		return <Children {...props} match={match} />
	}
}

export const fileUploadHandler = async (index, values, vaccineType) => {
	try {
		const updatedImages = await Promise.all(
			values.map(async (value) => {
				const fd = new FormData()
				fd.append("file", value)
				fd.append("upload_preset", "amazing")

				const res = await axios.post(
					"https://api.cloudinary.com/v1_1/amazing-pet-grooming/image/upload",
					fd
				)

				return {
					type:
						vaccineType === "bordatella"
							? "bordatellaImgUrl"
							: "vaccineRecordImgUrl",
					url: res.data.secure_url,
				}
			})
		)

		return updatedImages
	} catch (err) {
		console.log(err)
		return []
	}
}
