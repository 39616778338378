import React, { useState, useEffect, useContext } from "react";
import "../SideDrawer/DrawerToggleButton";
import DrawerToggleButton from "../SideDrawer/DrawerToggleButton";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "./style.scss";
import { RegistrationContext } from "../../contexts/registrationStatusContext";

const Toolbar = (props) => {
  const [employeeUsername, setEmployerUsername] = useState("");
  const [authorized, setAuthorized] = useState(false);
  const [jobType, setJobType] = useState("");
  // const [isRegistrationInProcess, setIsRegistrationInProcess] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const {
    isRegistrationInProcess,
    setIsRegistrationInProcess,
    setLimitedAccessToCambridgeAdmin,
    setSwitchToOldToolbar,
  } = useContext(RegistrationContext);

  useEffect(() => {
    if (window.innerWidth < 912) props.closeSideDrawerHandler();
    // eslint-disable-next-line
  }, [location.pathname, isRegistrationInProcess]);

  const accessString = localStorage.getItem("JWT");
  useEffect(() => {
    window.scrollTo(0, 0);
    if (accessString == null) {
      setAuthorized(false);
    } else {
      try {
        const employeeUsername = localStorage.getItem("USERNAME");
        const jobType = localStorage.getItem("JOBTYPE");
        setEmployerUsername(employeeUsername);
        setAuthorized(true);
        setJobType(jobType);
      } catch (error) {
        console.error(error);
      }
    }
  }, [accessString]);

  // 	//Logout User
  const handleLogOut = (e) => {
    e.preventDefault();
    setSwitchToOldToolbar(false);
    localStorage.removeItem("JWT");
    localStorage.removeItem("USERNAME");
    localStorage.removeItem("JOBTYPE");
    localStorage.removeItem("CITY");
    localStorage.removeItem("LIMITED_ACCESS_TO_CAMBRIDGE");
    localStorage.removeItem("OLD_TOOLBAR");
    setLimitedAccessToCambridgeAdmin(false);
    window.location.href = "/";
  };

  const registrationStatusChecker = (navLink) => {
    if (!isRegistrationInProcess) {
      return;
    } else if (
      window.confirm(
        "Your registration is not complete yet! Are you sure you want to leave? Your progress will be lost"
      )
    ) {
      console.log(`/${navLink}`);
      setIsRegistrationInProcess(false);
      navigate(`/${navLink}`);
    }
  };

  if (!authorized) {
    return (
      <header className="toolbar">
        <nav className="toolbar__navigation">
          <div className="toolbar__toggle-button">
            <DrawerToggleButton click={props.drawerClickHandler} />
          </div>
          {!isRegistrationInProcess ? (
            <Link to="/" className="toolbar__logo">
              <i className="fas fa-home homeIcon"></i> Amazing Pet Grooming{" "}
              <i className="fas fa-paw pawIconToolBar"></i>
            </Link>
          ) : (
            <div
              onClick={() => registrationStatusChecker("")}
              className="toolbar__logo"
            >
              <i className="fas fa-home homeIcon"></i> Amazing Pet Grooming{" "}
              <i className="fas fa-paw pawIconToolBar"></i>
            </div>
          )}
          <div className="spacer" />
          <div className="toolbar_navigation-items">
            {!isRegistrationInProcess ? (
              <div>
                <ul className="nav_times_wrapper">
                  <li>
                    <Link
                      className="nav-items-no-loggedin book-appointment-navbar"
                      to="/schedule"
                    >
                      Book Appointment {""}
                      <i className="far fa-calendar-alt"></i>
                    </Link>
                  </li>
                  <li>
                    <Link className="nav-items-no-loggedin" to="/about">
                      About Us
                    </Link>
                  </li>
                  <li>
                    <Link to="/services" className="nav-items-no-loggedin">
                      Services
                    </Link>
                  </li>
                  <li>
                    <Link to="/gallery" className="nav-items-no-loggedin">
                      Gallery
                    </Link>
                  </li>
                  <li>
                    <Link to="/contact" className="nav-items-no-loggedin">
                      Contact
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/clients_registration"
                      className="nav-items-no-loggedin"
                    >
                      Register
                    </Link>
                  </li>
                </ul>
              </div>
            ) : (
              <ul>
                <li>
                  <span
                    onClick={() => registrationStatusChecker("schedule")}
                    className="nav-items-no-loggedin book-appointment-navbar"
                  >
                    Book Appointment {""}
                    <i className="far fa-calendar-alt"></i>
                  </span>
                </li>
                <li>
                  <span
                    onClick={() => registrationStatusChecker("about")}
                    className="nav-items-no-loggedin"
                  >
                    About Us
                  </span>
                </li>
                <li>
                  <span
                    className="nav-items-no-loggedin"
                    onClick={() => registrationStatusChecker("services")}
                  >
                    Services
                  </span>
                </li>
                <li>
                  <span
                    className="nav-items-no-loggedin"
                    onClick={() => registrationStatusChecker("gallery")}
                  >
                    Gallery
                  </span>
                </li>
                <li>
                  <span
                    className="nav-items-no-loggedin"
                    onClick={() => registrationStatusChecker("contact")}
                  >
                    Contact
                  </span>
                </li>
                <li>
                  <span className="nav-items-no-loggedin">Register</span>
                </li>
              </ul>
            )}
          </div>
        </nav>
      </header>
    );
  } else {
    return (
      <header className="toolbar">
        <nav className="toolbar__navigation">
          <div />
          <div className="toolbar__toggle-button">
            <DrawerToggleButton click={props.drawerClickHandler} />
          </div>
          <div className="toolbar__logo">
            <Link to="/">
              <i className="fas fa-home homeIcon"></i>{" "}
              <span className="amazingpetgrooming-navbar">
                Amazing Pet Grooming
              </span>{" "}
              <i className="fas fa-paw pawIconToolBar"></i>
            </Link>
          </div>
          <div className="spacer" />
          <div className="toolbar_navigation-items">
            <ul>
              <li className="usernameLoggedinHome">
                <i className="fas fa-user"></i>&nbsp; Logged in as:
                {""} {employeeUsername}
              </li>
              <li className="logoutBtnNavBar" onClick={handleLogOut}>
                <i className="fas fa-sign-out-alt"></i>
                {""} Logout
              </li>
              {jobType === "receptionist" && (
                <>
                  <li className="commissionPage-btn">
                    {/* Comission Page */}
                    <Link
                      to="/auth/commission"
                      className=" commissionBtnControlPanel"
                    >
                      <i className="fas fa-dollar-sign"></i> Daily Report
                    </Link>
                  </li>

                  <li className="controlPanelNavbar">
                    <Link to="/auth/reception">
                      <i className="fas fa-cog"></i> Control Panel
                    </Link>
                  </li>
                </>
              )}

              {jobType === "admin" && (
                <div>
                  <li className="adminPanelBtnNavbar">
                    <Link
                      className="text-white adminBtnNavbar"
                      to="/auth/admin"
                    >
                      Admin Panel
                    </Link>
                  </li>
                </div>
              )}
            </ul>
          </div>
        </nav>
      </header>
    );
  }
};

export default Toolbar;
