import React, { useState, useContext, useEffect } from "react"
import axios from "axios"
import "./Signin.scss"
import { RegistrationContext } from "../../contexts/registrationStatusContext"
import { useNavigate } from "react-router-dom"
import CustomInput from "../CustomInput"
import { CustomButton } from "../CustomButton"
import { BsArrowsCollapse } from "react-icons/bs"
import { Link } from "react-router-dom"

export default function SignIn() {
	const [showForgotPassword, setShowForgotPassword] = useState(false)
	const [showSubItems, setShowSubItems] = useState(false)
	const [showSubItemsRegistration, setShowSubItemsRegistration] =
		useState(false)
	const [oldEmail, setOldEmail] = useState(false)
	const [email, setEmail] = useState("")
	const [password, setPassword] = useState("")
	const [errorMessage, setErrorMessage] = useState("")
	const [errorMessageForgotPassword, setErrorMessageForgotPassword] =
		useState("")
	const navigate = useNavigate()
	const {
		setShowLoginModal,
		setShowRegisterDaycareModal,
		setShowRegisterGroomingModal,
	} = useContext(RegistrationContext)

	useEffect(() => {
		if (!showForgotPassword) {
			setErrorMessageForgotPassword("")
		}
	}, [showForgotPassword])

	const handleSubmit = async (e) => {
		e.preventDefault()
		if (email === "" || password === "") {
			return alert("Both fields are required")
		} else {
			try {
				await axios.post("/auth/api/clients/signin", {
					email,
					password,
				})
				window.location.href = "/client-dashboard"
			} catch (error) {
				console.error(error.response)
				setErrorMessage(error.response.data.message)
			}
		}
	}

	const checkAccountExistsHandler = async (e) => {
		e.preventDefault()
		if (!oldEmail) {
			return
		}
		await axios
			.get(`/api/client-email-check/${oldEmail}`)
			.then(() => {
				alert("You will receive an email shortly.")
				window.location.reload()
			})
			.catch((err) => {
				console.log(err)
				setErrorMessageForgotPassword(err.response.data.error)
			})
	}

	return (
		<div className="w-full  flex items-center justify-center relative">
			<div
				className="absolute top-4 right-4 cursor-pointer"
				onClick={() => setShowLoginModal(false)}
			>
				X
			</div>
			<div className=" w-full  h-[50%] md:h-full border-main bg-lightBlue rounded-default p-phone flex flex-col gap-[30px]">
				<img
					alt="-signin"
					src="/images/newLogo.png"
					className="logo-signin-form"
				/>
				<form
					className="w-full h-full flex flex-col justify-between"
					onSubmit={handleSubmit}
				>
					<div className="w-full flex flex-col gap-[10px]">
						<h5 className="text-black font-black text-[20px] md:text-[30px] mb-[10px] text-center">
							👤 Clients Login
						</h5>
						<CustomInput
							readOnlyInput={showForgotPassword}
							type="email"
							id="email"
							value={email}
							onChange={(e) => setEmail(e.target.value)}
							placeholder="* Email"
							color="#65BFF8"
						/>{" "}
						<CustomInput
							readOnlyInput={showForgotPassword}
							type="password"
							id="password"
							value={password}
							onChange={(e) => setPassword(e.target.value)}
							placeholder="* Password"
							color="#65BFF8"
						/>
					</div>
					<div className="w-full relative mt-2">
						<CustomButton disabledBtn={showForgotPassword} fullWidth>
							Login
						</CustomButton>
						<p className="text-red-400 text-center mt-2">{errorMessage}</p>
					</div>
				</form>
				<div className="text-center">
					<span>
						Don't have an account yet?{" "}
						<div
							className="cursor-pointer itemMainDesktopMenu relative z-10"
							onClick={() =>
								setShowSubItemsRegistration(!showSubItemsRegistration)
							}
						>
							<div className="text-main hover:text-[#019DFF] hover:no-underline">
								Click here to register now
							</div>
							{showSubItemsRegistration && (
								<div className={`bg-white transition-all duration-300`}>
									<div
										onClick={() => setShowRegisterDaycareModal(true)}
										className="text-main hover:text-[#019DFF] hover:no-underline"
									>
										Daycare
									</div>
									<Link
										onClick={() => setShowRegisterGroomingModal(true)}
										className="text-main mt-4 hover:text-[#019DFF] hover:no-underline"
									>
										Grooming
									</Link>
								</div>
							)}
						</div>
					</span>
				</div>

				<span
					onClick={() => setShowForgotPassword(!showForgotPassword)}
					className="text-center cursor-pointer"
				>
					Forgot Password? <BsArrowsCollapse className="inline-block" />
				</span>
				{showForgotPassword && (
					<form
						className="bg-slate-300 p-2"
						onSubmit={checkAccountExistsHandler}
					>
						<p className="mt-4 text-md text-center">
							Enter the email associated with your account <br />
							&#8595;
						</p>
						<div className="relative">
							<input
								className="py-2 pl-4 pr-12 w-full text-black border border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
								type={"email"}
								onChange={(e) => setOldEmail(e.target.value)}
							/>
						</div>
						<p className="error-message-registration mt-4 text-center">
							{errorMessageForgotPassword}
						</p>
						<button className="w-full my-4 bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 focus:outline-none focus:bg-blue-600">
							Submit
						</button>
					</form>
				)}
			</div>
		</div>
	)
}
