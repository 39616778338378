import React from "react";

const convertHexToRgba = (colorHex) => {
	colorHex = colorHex.replace("#", "");

	const r = parseInt(colorHex.substring(0, 2), 16);
	const g = parseInt(colorHex.substring(2, 4), 16);
	const b = parseInt(colorHex.substring(4, 6), 16);

	const opacity = 0.5;

	const rgbaColor = `rgba(${r}, ${g}, ${b}, ${opacity})`;

	return rgbaColor;
};

const ImageGradient = ({
	image,
	color,
	bigImage = false,
	centered = false,
}) => {
	return (
		<div
			className="w-full h-full relative select-none"
			style={{
				background: `radial-gradient(50% 50.00% at 50% 50.00%, ${convertHexToRgba(
					color
				)} 0%, ${color} 100%)`,
			}}
		>
			<img
				className={`absolute bottom-0 left-1/2 transform -translate-x-1/2 ${
					bigImage ? "w-[60%]" : "w-1/3"
				} ${centered && "top-1/2"}}`}
				style={{
					transform: centered && "translate(-50%, -50%)",
					bottom: centered && "0%",
				}}
				src={image}
				alt="image with gradient background"
			/>
		</div>
	);
};

export default ImageGradient;
