import axios from "axios"
import moment from "moment"
import { useEffect, useState } from "react"
import Select from "react-select"
import { Modal, ModalBody, ModalHeader } from "reactstrap"
import { daycarePassOptions } from "../../utils/constants"
import { v4 as uuidv4 } from "uuid"

const NO_VISITS_LEFT = "No visits left"
const PASS_EXPIRED = "Pass Expired"
const ACTIVE = "Active"

export default function ClientAccount({ refetchData, data }) {
	const [modalToSaleMembership, setModalToSaleMembership] = useState(false)
	const [pass, setPass] = useState("")
	const [updatedDate, setUpdatedDate] = useState("")
	const [visits, setVisits] = useState([])
	const accessString = localStorage.getItem("JWT")
	const JOBTYPE = localStorage.getItem("JOBTYPE")

	useEffect(() => {
		getClientDaycareVisits()
	}, [])

	const salePassHandler = async () => {
		const passObj = {
			name: pass.value,
			type: "pass",
			purchaseSource: "in-person",
			value: pass.cost,
			balance: 0,
			originalNumOfVisits: pass.numOfVisits,
			numOfVisitsLeft: pass.numOfVisits,
			orderNumber: uuidv4(),
			expireDate: moment().add(1, "year"),
			updatedDate: moment(),
			createdDate: moment(),
			ClientId: data.id,
		}
		if (window.confirm("Do you confirm the Daycare Pass Sale")) {
			await axios
				.post(`/api/membership/${data.id}/milton`, passObj)
				.then(() => {
					refetchData()
					setModalToSaleMembership()
				})
				.catch((err) => console.log(err))
		}
	}

	const getClientDaycareVisits = async () => {
		const allVisits = await axios.get(
			`/auth/api/all_daycareVisits_by_client_id/${data.id}`,
			{
				headers: { JWT: accessString },
			}
		)
		setVisits(allVisits)
	}

	const renderVisits =
		visits.data && visits.data.length > 0 ? (
			visits.data.map((daycareVisit) =>
				daycareVisit.groomerName === "daycare" ? (
					<div
						className="w-full p-2 border flex flex-col items-center"
						key={daycareVisit.id}
					>
						<p>Date: {moment(daycareVisit.date).format("YYYY-MM-DD")}</p>
						<p>Pet Name: {daycareVisit.nameBreed}</p>
					</div>
				) : null
			)
		) : (
			<div>No visits history available</div>
		)

	const updatePassDateHandler = async (id) => {
		if (!updatedDate) {
			return
		}
		const passObj = {
			expireDate: moment(updatedDate).format("YYYY-MM-DD 23:59:59"),
		}
		await axios.put(`/api/membership/${id}`, passObj)
		refetchData()
	}

	return (
		<div>
			<h3>Client Account</h3>
			<div className="flex my-4 flex-row gap-3 justify-between">
				<button
					onClick={() => setModalToSaleMembership(true)}
					className="bg-cyan-950 py-2 text-xl w-1/2 text-white"
				>
					Sell a Pass or Membership
				</button>
			</div>
			<div className="flex gap-4 flex-row">
				<div className="w-1/2">
					<h5>Current Passes/Membership:</h5>
					<div className="flex gap-2 p-2 flex-col sm:flex-row justify-between flex-wrap">
						{data && data.Memberships.length ? (
							data.Memberships.map((pass) => {
								return (
									<div
										className={`flex-1 rounded-sm w-full sm:w-1/4 p-4 mt-4 ${
											pass.numOfVisitsLeft === 0 ||
											moment(pass.expireDate).isBefore(moment())
												? "border-8 border-red-500 "
												: "border-8 border-green-500 "
										}`}
										key={pass.id}
									>
										{JOBTYPE === "admin" && (
											<div>
												<span>Edit Exp. Date</span>
												<div className="flex gap-1 border mb-4 flex-rox justify-between">
													<input
														onChange={(e) => setUpdatedDate(e.target.value)}
														type="date"
													/>
													<button
														onClick={() => updatePassDateHandler(pass.id)}
														className="bg-cyan-600 px-2 text-sm text-white"
													>
														Edit
													</button>
												</div>
											</div>
										)}
										<h5>
											Status:{" "}
											{pass.numOfVisitsLeft === 0
												? NO_VISITS_LEFT
												: moment().isBefore(pass.expireDate)
												? ACTIVE
												: PASS_EXPIRED}{" "}
											{pass.numOfVisitsLeft > 0 &&
												`Good until: ${moment(pass.expireDate).format(
													"YYYY-MM-DD"
												)}`}
										</h5>
										<p className="capitalize">Pass Name: {pass.name}</p>
										<p className="capitalize">Pass Value: {pass.value}</p>
										<p className="capitalize">
											Purchase Source: {pass.purchaseSource}
										</p>
										<p className="capitalize">
											Original Number of visits: {pass.originalNumOfVisits}
										</p>
										<p className="capitalize">
											Number of visits left: {pass.numOfVisitsLeft}
										</p>
										<p className="capitalize">
											Purchase date:{" "}
											{moment(pass.createdDate).format("YYYY-MM-DD")}
										</p>
										<p className="capitalize">
											Order Number: {pass.orderNumber}
										</p>
									</div>
								)
							})
						) : (
							<div>
								No Passes or Memberships have been purchased by this client
							</div>
						)}
					</div>
				</div>
				<div className="w-1/2 h-400 border overflow-y-auto">
					<h5 className="pl-2 pt-2">Daycare Visits History:</h5>
					<div
						style={{ height: "100vh", overflowY: "auto" }}
						className="flex gap-2 mt-4 p-2 flex-col sm:flex-row justify-between flex-wrap h-400 overflow-y-auto"
					>
						{renderVisits}
					</div>
				</div>
			</div>
			<Modal
				isOpen={modalToSaleMembership}
				toggle={() => setModalToSaleMembership(!modalToSaleMembership)}
			>
				<ModalHeader
					toggle={() => setModalToSaleMembership(!modalToSaleMembership)}
				>
					Pass or Membership sale
				</ModalHeader>
				<ModalBody>
					<Select
						onChange={(value) => setPass(value)}
						options={daycarePassOptions}
					/>
					<button
						className="btn btn-info mt-2 btn-block"
						onClick={salePassHandler}
					>
						Confirm Sale
					</button>
				</ModalBody>
			</Modal>
		</div>
	)
}
