export default function Index() {
	return (
		<div className="pb-12">
			<h4>Available Daycare passes (to be purchased in person)</h4>
			<div className="border p-4">
				<h4>Half Day Passes</h4>
				<div className="flex flex-row flex-wrap justify-between">
					<div className="card w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/4 mx-4 text-center p-4">
						<h5>5 Half Day Visits</h5>
						<img
							className="w-full md:w-[70%] mx-auto"
							src="/images/passes/daycare_5_HD.png"
							alt="half day 5 day pass"
						/>
					</div>
					<div className="card w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/4 mx-4 text-center p-4">
						<h5>10 Half Day Visits</h5>
						<img
							className="w-full md:w-[70%] mx-auto"
							src="/images/passes/daycare_10_HD.png"
							alt="half day 10 day pass"
						/>
					</div>
					<div className="card w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/4 mx-4 text-center p-4">
						<h5>20 Half Day Visits</h5>
						<img
							className="w-full md:w-[70%] mx-auto"
							src="/images/passes/daycare_20_HD.png"
							alt="half day 20 day pass"
						/>
					</div>
				</div>
			</div>

			<div className="border p-4 mt-4">
				<h4>Full Day Passes</h4>
				<div className="flex flex-row flex-wrap gap-2 justify-between">
					<div className="card w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/4 mx-4 text-center p-4">
						<h5>5 Full Day Visits</h5>
						<img
							className="w-full md:w-[70%] mx-auto"
							src="/images/passes/5-day-FD.png"
							alt="Full day 5 day pass"
						/>
					</div>
					<div className="card w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/4 mx-4 text-center p-4">
						<h5>10 Full Day Visits</h5>
						<img
							className="w-full md:w-[70%] mx-auto"
							src="/images/passes/10-day-FD.png"
							alt="Full day 10 day pass"
						/>
					</div>
					<div className="card w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/4 mx-4 text-center p-4">
						<h5>20 Full Day Visits</h5>
						<img
							className="w-full md:w-[70%] mx-auto"
							src="/images/passes/20-day-FD.png"
							alt="Full day 20 day pass"
						/>
					</div>
				</div>
			</div>
		</div>
	)
}
