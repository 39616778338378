import React, { useLayoutEffect, useRef } from "react";
import { CgArrowLongRight } from "react-icons/cg";
import { gsap } from "gsap";

export const CustomButton = ({
  children,
  fullWidth = false,
  onClick,
  hasArrow = false,
  oppositeColor = false,
  areAllFieldsReady,
  disabledBtn,
  className,
}) => {
  const button = useRef(null);
  const tl = useRef(null);

  useLayoutEffect(() => {
    tl.current = gsap.timeline({ paused: true });
    tl.current.to(button.current, {
      duration: 0.5,
      boxShadow: "2px 4px 12px 0px rgba(0, 156, 255, 0.10)",
      y: -5,
      ease: "Power3.easeOut",
    });
  }, []);

  const handleMouseEnter = () => {
    tl.current.play();
  };

  const handleMouseLeave = () => {
    tl.current.duration(0.2);
    tl.current.reverse();
  };
  return (
    <button
      disabled={disabledBtn}
      className={`${
        areAllFieldsReady
          ? "button-flashing-submit text-white"
          : oppositeColor
          ? "text-white border-white bg-lightBlue"
          : "text-main border-main bg-white"
      } ${
        fullWidth && "w-full"
      } ${className} py-[10px] px-[40px] border-[1px] cursor-pointer rounded-default flex justify-center items-center gap-[10px] leading-none`}
      onClick={onClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      ref={button}
    >
      {children}
      {hasArrow && (
        <CgArrowLongRight
          size="16"
          color={oppositeColor ? "#FFFFFF" : "#65BFF8"}
        />
      )}
    </button>
  );
};
