import React from "react";
import { CustomButton } from "../../CustomButton";
import ValidationCard from "../ValidationCard";

const Validation = ({ onClick, answers = [] }) => {
  return (
    <>
      <h5 className="text-black font-black text-[30px] md:text-[45px]">
        What you have chosen:
      </h5>

      {/* Form choises */}
      <div className="flex flex-col justify-between w-full">
        <div className="w-full grid grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-[30px]">
          {answers.map((answer) => (
            <ValidationCard
              color="#65BFF8"
              image={answer.image}
              text={answer.answer}
              bigImage={answer.bigImage}
              onClick={answer.onClick}
              key={answer.answer}
            />
          ))}
        </div>

        <div className="w-full h-auto mt-8">
          <CustomButton fullWidth hasArrow onClick={onClick}>
            Continue
          </CustomButton>
        </div>
      </div>
    </>
  );
};

export default Validation;
