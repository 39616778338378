import React, { useState } from "react"
import { FaEye } from "react-icons/fa"
import { Modal } from "react-bootstrap"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

const ReportRow = ({ report, reportType }) => {
	if (!report) return null

	const [showModal, setShowModal] = useState(false)

	const handleShow = () => setShowModal(true)
	const handleClose = () => setShowModal(false)

	const copyToClipboard = (text) => {
		navigator.clipboard.writeText(text).then(() => {
			notify(text)
		})
	}

	const notify = (msgContent) => toast(`Copied ${msgContent} to Clipboard!`)

	const {
		groomerName,
		totalSales,
		totalTips,
		totalCommission,
		workedOnDayOff,
		fiftyPercentCommission,
		sixtyPercentCommission,
		totalSalesAllEmployees,
		totalSalesAllEmployeesCommission,
		grandTotal,
		valueForPaystub,
		valueForCash,
	} = report

	return (
		<>
			<ToastContainer
				position="top-right"
				autoClose={1500}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
				theme="light"
			/>
			<tr>
				<td>{groomerName?.toUpperCase()}</td>
				<td>{`$${totalSales}`}</td>
				<td>
					{reportType !== "daycare" && totalCommission ? (
						<>
							{`50% = $${fiftyPercentCommission}, 60% = $${sixtyPercentCommission} `}
							<span
								className="cursor-pointer font-bold hover:text-green-400"
								onClick={() => copyToClipboard(totalCommission)}
							>{`(Total = $${totalCommission})`}</span>
						</>
					) : (
						"-"
					)}
				</td>
				<td>
					{totalTips ? (
						<span
							className="cursor-pointer font-bold hover:text-green-400"
							onClick={() => copyToClipboard(totalTips)}
						>
							${totalTips}
						</span>
					) : (
						"-"
					)}
				</td>
				<td>
					{workedOnDayOff !== undefined ? (workedOnDayOff ? "YES" : "NO") : "-"}
				</td>
				<td className="text-success">
					{grandTotal
						? reportType === "daycare"
							? `$${totalSales}`
							: `$${grandTotal?.toFixed(2)}`
						: "-"}
				</td>
				<td>{valueForPaystub ? `$${valueForPaystub}` : "-"}</td>
				<td className="text-danger">
					{valueForCash < 0
						? "Not enough commissions to receive cash portion"
						: valueForCash !== undefined
						? `$${valueForCash}`
						: "-"}
				</td>
				<td className="text-center">
					<FaEye
						color="blue"
						onClick={handleShow}
						style={{ cursor: "pointer" }}
					/>
				</td>
			</tr>

			{/* Modal for detailed information */}
			<Modal show={showModal} onHide={handleClose}>
				<Modal.Header closeButton>
					<Modal.Title>Report Details</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<h6>
						{reportType === "daycare" ? "Service" : "Employee"}:{" "}
						{groomerName?.toUpperCase()}
					</h6>
					{reportType !== "daycare" && (
						<p>
							Total Employee Sales: $
							<span
								className="cursor-pointer hover:text-green-400"
								onClick={() => copyToClipboard(totalSales)}
							>
								{totalSales}
							</span>
						</p>
					)}

					<p>
						Total Employee Tips:{" "}
						{totalTips ? (
							<span
								className="cursor-pointer hover:text-green-400"
								onClick={() => copyToClipboard(totalTips)}
							>
								${totalTips}
							</span>
						) : (
							"No tips"
						)}
					</p>
					{reportType !== "daycare" && totalCommission && (
						<p>
							Total Commission (50% = ${fiftyPercentCommission}, 60% = $
							{sixtyPercentCommission}):
							<b
								className="cursor-pointer hover:text-green-400"
								onClick={() => copyToClipboard(totalCommission)}
							>
								{" "}
								${totalCommission}
							</b>
						</p>
					)}
					{reportType !== "daycare" && (
						<p>Worked on Day Off: {workedOnDayOff ? "Yes" : "No"}</p>
					)}

					{groomerName === "paola" && totalSalesAllEmployees && (
						<p>
							Total Sales for All Employees: ${totalSalesAllEmployees}{" "}
							(Commission: ${totalSalesAllEmployeesCommission?.toFixed(2)})
						</p>
					)}
					<p>Grand Total: ${grandTotal?.toFixed(2)}</p>
					{reportType !== "daycare" && valueForPaystub && (
						<p>Amount for Paystub: ${valueForPaystub}</p>
					)}

					{reportType !== "daycare" && valueForCash && (
						<p>
							Amount in Cash:{" "}
							{valueForCash < 0
								? "Not enough commissions to receive cash portion"
								: `$${valueForCash}`}
						</p>
					)}
				</Modal.Body>
			</Modal>
		</>
	)
}

export default ReportRow
