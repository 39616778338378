import React, { useState, useEffect } from "react";
import "./App.scss";
// import packageJson from "./package.json";
// import { getBuildDate } from "./utils/utils";
import withClearCache from "./ClearCache";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Toolbar from "./components/Toolbar";
import OldToolbar from "./components/OldToolbar";
import SideDrawer from "./components/SideDrawer/SideDrawer";
import Backdrop from "./components/Backdrop";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Gallery from "./pages/Gallery";
import Home from "./pages/Home";
import EmployeesProfile from "./pages/EmployeesProfile";
import Receptionist from "./pages/Receptionist";
import ReceptionistCambridge from "./pages/ReceptionistCambridge";
import PhotoManager from "./pages/PhotoManager";
import CustomerAddPage from "./pages/CustomerAddPage";
import CustomerAddPetPage from "./pages/CustomerAddPetPage";
import CustomerAddPageAllCities from "./pages/CustomerAddPageAllCities";
import CustomerAddPetPageCambridge from "./pages/CustomerAddPetPageAllCities";
import Commission from "./pages/Commission";
import Services from "./pages/Services";
import Daycare from "./pages/Daycare";
import ClientRegistration from "./pages/ClientRegistration";
import Footer from "./components/Footer";
import EditClients from "./components/EditClients";
import EditClientsCambridge from "./components/EditClientsCambridge";
import EditEmployees from "./components/EditEmployees";
import SignIn from "./components/SignIn/SignIn";
import ClientsLoginForm from "./components/SignIn/SignInClients";
import TermsAndConditions from "./pages/TermsAndConditions";
import NoMatch from "./pages/NoMatch/index";
import Staff from "./pages/Staff";
import Schedule from "./pages/Schedule";
import { RegistrationContext } from "./contexts/registrationStatusContext";
import { Modal, ModalBody } from "reactstrap";
import ClientRegistrationModal from "./components/Modals/ClientRegistrationModal";
import TrialDayBookModal from "./components/Modals/TrialDayBookModal";
import DaycareBookModal from "./components/Modals/DaycareBookModal";
import "./index.css";
import Cookies from "js-cookie";
import ClientDashboard from "./pages/ClientDashboard";
import { jwtDecode } from "jwt-decode";

const ClearCacheComponent = withClearCache(MainApp);

function App() {
  return <ClearCacheComponent />;
}

function MainApp(props) {
  const isAuthenticated = !!Cookies.get("authtoken");

  const [sideDrawerOpen, setSideDrawerOpen] = useState(false);
  const [toolbarState, setToolbarState] = useState(true);
  const [isRegistrationInProcess, setIsRegistrationInProcess] = useState(false);
  const [hasUserSeenPopUp, setHasUserSeenPopUp] = useState(false);
  const [limitedAccessToCambridgeAdmin, setLimitedAccessToCambridgeAdmin] =
    useState(false);
  const [switchToOldToolbar, setSwitchToOldToolbar] = useState(false);
  const [showBookTrialDayModal, setShowBookTrialDayModal] = useState(false);
  const [showBookDaycareModal, setShowBookDaycareModal] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showRegisterDaycareModal, setShowRegisterDaycareModal] =
    useState(false);
  const [showRegisterGroomingModal, setShowRegisterGroomingModal] =
    useState(false);
  const [accDetailsForDaycare, setAccDetailsForDaycare] = useState(null);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    petName: "",
    breed: "",
    agreeTerms: false,
  });
  const [commissions, setCommissions] = useState([]);

  useEffect(() => {
    const accessString = localStorage.getItem("JOBTYPE");
    setHasUserSeenPopUp(true);
    if (accessString === "customer") {
      setToolbarState(false);
    }
  }, []);

  useEffect(() => {
    const isOldToolbarActive = localStorage.getItem("OLD_TOOLBAR");
    localStorage.removeItem("FOUND_ACCOUNT");
    if (isOldToolbarActive != null) {
      setSwitchToOldToolbar(true);
    }
  }, []);

  useEffect(() => {
    const token = Cookies.get("authtoken");
    if (token) {
      const decodedToken = jwtDecode(token);
      if (decodedToken.exp * 1000 < Date.now()) {
        logoutHandler();
      }
    }
  }, [isAuthenticated]);

  const logoutHandler = () => {
    Cookies.remove("authtoken");
    Cookies.remove("client_location");
    window.location.reload();
  };

  let backdrop;

  const backdropClickHandler = () => {
    setSideDrawerOpen(!sideDrawerOpen);
  };

  const closeSideDrawerHandler = () => {
    setSideDrawerOpen(false);
  };

  const drawerToggleClickHandler = () => {
    setSideDrawerOpen(!sideDrawerOpen);
  };

  if (sideDrawerOpen) {
    backdrop = <Backdrop click={backdropClickHandler} />;
  }

  return (
    <div>
      <RegistrationContext.Provider
        value={{
          isRegistrationInProcess,
          setIsRegistrationInProcess,
          hasUserSeenPopUp,
          setHasUserSeenPopUp,
          closeSideDrawerHandler,
          limitedAccessToCambridgeAdmin,
          setLimitedAccessToCambridgeAdmin,
          switchToOldToolbar,
          setSwitchToOldToolbar,
          accDetailsForDaycare,
          setAccDetailsForDaycare,
          showLoginModal,
          setShowLoginModal,
          showRegisterDaycareModal,
          setShowRegisterDaycareModal,
          showRegisterGroomingModal,
          setShowRegisterGroomingModal,
          isAuthenticated,
          showBookTrialDayModal,
          setShowBookTrialDayModal,
          showBookDaycareModal,
          setShowBookDaycareModal,
          formData,
          setFormData,
          commissions,
          setCommissions,
        }}
      >
        <Router>
          <header style={{ height: "100%" }}>
            {toolbarState &&
              (switchToOldToolbar ? (
                <>
                  <OldToolbar
                    drawerClickHandler={drawerToggleClickHandler}
                    closeSideDrawerHandler={closeSideDrawerHandler}
                  />
                  <SideDrawer show={sideDrawerOpen} />
                  {backdrop}
                </>
              ) : (
                // New toolbar
                <Toolbar
                  drawerClickHandler={drawerToggleClickHandler}
                  closeSideDrawerHandler={closeSideDrawerHandler}
                />
              ))}
          </header>
          <TrialDayBookModal />
          <DaycareBookModal />
          {showLoginModal && (
            <Modal
              isOpen={showLoginModal}
              toggle={() => setShowLoginModal(!showLoginModal)}
            >
              <ModalBody>
                <ClientsLoginForm />
              </ModalBody>
            </Modal>
          )}
          <ClientRegistrationModal
            isModalOpen={showRegisterDaycareModal}
            toggleModal={setShowRegisterDaycareModal}
            modalType="daycare"
          />
          <ClientRegistrationModal
            isModalOpen={showRegisterGroomingModal}
            toggleModal={setShowRegisterGroomingModal}
            modalType="grooming"
          />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/clients/milton/:id" element={<EditClients />} />
            <Route
              path="/clients/cambridge/:id"
              element={<EditClientsCambridge />}
            />
            <Route
              path="/client-dashboard"
              element={
                // <ClientDashboard />
                isAuthenticated ? (
                  <ClientDashboard />
                ) : (
                  <Navigate to="/" replace={true} />
                )
              }
            />

            <Route
              path="/auth/customer_milton/:regid"
              element={<CustomerAddPetPage />}
            />
            <Route
              path="/auth/customer_cambridge/:regid"
              element={<CustomerAddPetPageCambridge />}
            />
            <Route path="/auth/photo_manager" element={<PhotoManager />} />
            <Route path="/auth/reception" element={<Receptionist />} />
            <Route
              path="/auth/reception/cambridge"
              element={<ReceptionistCambridge />}
            />
            <Route path="/auth/customer" element={<CustomerAddPage />} />
            <Route
              path="/clients_registration"
              element={<CustomerAddPageAllCities />}
            />
            <Route
              path="/daycare_registration"
              element={<ClientRegistration mode="daycare" />}
            />
            <Route
              path="/grooming_registration"
              element={<ClientRegistration mode="grooming" />}
            />
            <Route path="/auth/commission" element={<Commission />} />
            <Route path="/auth/employees/:id" element={<EditEmployees />} />
            <Route path="/about" element={<About />} />
            <Route path="/services" element={<Services />} />
            <Route path="/daycare" element={<Daycare />} />
            <Route path="/gallery" element={<Gallery />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/schedule" element={<Schedule />} />
            <Route path="/auth/admin" element={<Staff />} />
            <Route
              path="/auth/employees_profile"
              element={<EmployeesProfile />}
            />
            <Route
              path="/terms-and-conditions"
              element={<TermsAndConditions />}
            />
            <Route path="/auth/login" element={<SignIn />} />
            <Route path="/auth/logout" element={<SignIn />} />
            <Route element={NoMatch} />
          </Routes>
          {!isAuthenticated && <Footer />}
        </Router>
      </RegistrationContext.Provider>
    </div>
  );
}

export default App;
