import React, { useState, useEffect, useContext } from "react"
import axios from "axios"
import { Link } from "react-router-dom"
import API from "../../utils/API"
import MainCalendar from "../../components/Calendars/MainCalendar"
import SearchClients from "../../components/SearchClients"
import { FaSquareArrowUpRight } from "react-icons/fa6"
import { CgSandClock } from "react-icons/cg"
import WaitListAdmin from "../../components/WaitListAdmin"
import ModalSearchResult from "./ModalSearchAll"
import ModalToAddClients from "./ModalToAddClients"
import ModalOptionAddClientsDisabled from "./ModalOptionAddClientsDisabled"
import ClientsCambridge from "../../components/ClientsCambridge"
import { Helmet } from "react-helmet"
import moment from "moment"
import { RegistrationContext } from "../../contexts/registrationStatusContext"
const TODAY_DATE = moment(new Date()).format("YYYY-MM-DD")

const Index = () => {
	const { setCommissions } = useContext(RegistrationContext)
	const [dates, setDates] = useState({
		paola: new Date(),
		claudia: new Date(),
		peppa: new Date(),
		adriana: new Date(),
		mercedes: new Date(),
		bath_Cambridge: new Date(),
	})
	const [clients, setClients] = useState([])
	const [clientsCambridge, setClientsCambridge] = useState([])
	const [showClientsCambridgeTable, setShowClientsCambridgeTable] =
		useState(false)
	const [isLoading, setIsLoading] = useState(true)
	useState(localStorage.getItem("USERNAME") === "paoyepes" ? true : false)
	const [error, setError] = useState(false)
	const [toggleSearchClientByPhoneForm, setToggleSearchClientByPhoneForm] =
		useState(false)
	const [modalCambridgeCal, setModalCambridgeCal] = useState(false)
	const [modalToAddClients, setModalToAddClients] = useState(false)
	const [modalSearchResult, setModalSearchResult] = useState(false)
	const [searchAppointmentsForm, setSearchAppointmentsForm] = useState(false)
	const [searchAppointmentField, setSearchAppointmentField] = useState("")
	const [searchResultsDetails, setSearchResultsDetails] = useState([])
	const [jobType, setJobType] = useState("")
	const [toggleClientsTable, setToggleClientsTable] = useState(false)
	const [toggleCalendars, setToggleCalendars] = useState(true)
	const [waitList, setWaitList] = useState(false)
	const [triggerFetchEvents, setTriggerFetchEvents] = useState(false)

	// const [clientIdResult, setClientIdResult] = useState("");
	const [city, setCity] = useState("")

	useEffect(() => {
		setCity("cambridge")
	}, [])

	useEffect(() => {
		if ("scrollRestoration" in window.history) {
			window.history.scrollRestoration = "manual"
		}
		window.scrollTo(0, 0)

		const accessString = localStorage.getItem("JWT")
		if (accessString == null) {
			setIsLoading(false)
			setError(true)
		} else {
			try {
				async function fetchAuth() {
					await axios
						.get("/auth/employees_profile", {
							headers: { Authorization: `JWT ${accessString}` },
						})
						.then((res) => {
							setJobType(res.data.jobType)
						})
				}

				fetchAuth()

				setIsLoading(false)
				setError(false)
				localStorage.setItem("CITY", "cambridge")
				getAllCommissions()
			} catch (error) {
				console.error(error.response)
				setError(true)
			}
		}
	}, [])

	const handleNavigation = (direction) => {
		setDates((prevDates) => {
			const updatedDates = {}
			const increment = direction === "next" ? 1 : -1

			Object.keys(prevDates).forEach((calendarId) => {
				updatedDates[calendarId] = new Date(
					prevDates[calendarId].getFullYear(),
					prevDates[calendarId].getMonth(),
					prevDates[calendarId].getDate() + increment
				)
			})

			return updatedDates
		})
	}

	const handleCalendarNavigate = (calendarId, newDate) => {
		setDates((prevDates) => ({
			...prevDates,
			[calendarId]: newDate,
		}))
	}

	const getAllCommissions = async () => {
		await axios
			.get("/auth/salaries/hourly/commission/" + TODAY_DATE)
			.then((res) => {
				setCommissions(res.data)
			})
			.catch((err) => {
				console.log(err)
			})
	}

	const getAllClientsCambridge = async () => {
		let accessString = localStorage.getItem("JWT")
		await axios
			.get("/api/clients_cambridge", {
				headers: { jwt: `${accessString}` },
			})
			.then((res) => {
				const filteredClients = res.data.filter((client) => !client.deletedAt)
				setClientsCambridge(filteredClients)
			})
			.catch((err) => console.log(err))
	}

	const clientsCambridgeHandler = async () => {
		getAllClientsCambridge()
		localStorage.setItem("CITY", "cambridge")
		setShowClientsCambridgeTable(true)
		setToggleClientsTable(false)
		setToggleCalendars(false)
		setWaitList(false)
		if (toggleSearchClientByPhoneForm) {
			setToggleSearchClientByPhoneForm(false)
		}
	}

	const handleToggleCalendars = () => {
		setToggleCalendars(!toggleCalendars)
		setWaitList(false)
		setToggleClientsTable(false)
		setShowClientsCambridgeTable(false)
	}
	const handleToggleWaitlist = () => {
		setWaitList(!waitList)
		setToggleCalendars(false)
		setToggleClientsTable(false)
		setShowClientsCambridgeTable(false)
	}

	const toggleFormToSearchByPhone = () => {
		setToggleSearchClientByPhoneForm(!toggleSearchClientByPhoneForm)
		setWaitList(false)
		if (searchAppointmentsForm) {
			setSearchAppointmentsForm(false)
		}
		if (showClientsCambridgeTable) {
			setShowClientsCambridgeTable(false)
			setToggleCalendars(true)
		}
	}

	const handleDeleteClient = (id) => {
		let url
		const clientDeletedBy = {
			deletedAt: moment(new Date()).format("YYYY/MM/DD hh:mm"),
			deletedBy: localStorage.getItem("USERNAME"),
		}

		if (city === "MILTON") {
			url = API.softDeleteClient
		} else {
			url = API.softDeleteClientCambridge
		}
		if (
			window.confirm(`Are you sure you wish to delete this client permanently?`)
		) {
			url(id, clientDeletedBy)
				.then(() => {
					alert(
						"Client with Id number: " + id + " has been successfully deleted!"
					)
					getAllClientsCambridge()
				})
				.catch((err) => console.log(err))
		}
	}

	const toggleModalSearchResults = () => {
		setModalSearchResult(!modalSearchResult)
	}

	const toggleSearchAppointments = () => {
		setSearchAppointmentsForm(!searchAppointmentsForm)
		setWaitList(false)
		if (toggleSearchClientByPhoneForm) {
			setToggleSearchClientByPhoneForm(false)
		}
	}

	if (isLoading) {
		return (
			<div
				style={{
					marginTop: "120px",
					marginLeft: "10%",
					fontSize: "30px",
					height: "100vh",
				}}
			>
				Loading...if it does not respond, refresh this page
			</div>
		)
	}

	if (error) {
		return (
			<div
				style={{
					marginLeft: "10%",
					marginTop: "120px",
					fontSize: "30px",
					height: "100vh",
				}}
			>
				...Problem fetching user data. Please login again
				<span role="img" aria-label="Face With Rolling Eyes Emoji">
					🙄
				</span>
			</div>
		)
	}

	if (jobType === "receptionist" || jobType === "admin") {
		const handleSearchAppointmentsSubmit = async (e) => {
			e !== undefined && e.preventDefault()

			await axios
				.get(`/schedule/calendar/search/all/${searchAppointmentField}`, {
					title: searchAppointmentField,
				})
				.then((res) => {
					const filteredEvents = res.data.filter(
						(event) =>
							event.city === "cambridge" &&
							!event.deletedAt &&
							!event.isBeingBooked
					)
					setSearchResultsDetails(filteredEvents)
					toggleModalSearchResults()
				})
				.catch((err) => console.log(err))
		}

		return (
			<div className="pt-16 mt-12 bg-blue-50">
				<Helmet>
					<title>Dashboard</title>
				</Helmet>
				<div className="">
					<div className="row justify-content-center control-panel-container">
						<h1 className="flex sm:text-xl px-3 text-[20px] flex-wrap text-center">
							<span className="px-1 mr-2 text-blue-800 border-2 bg-cyan-400 border-cyan-500">
								Cambridge
							</span>{" "}
							Main Desk Control Panel{" "}
							<Link
								className="inline-block w-full sm:inline sm:w-72"
								to={"/auth/reception"}
								color="dark"
							>
								<button className="px-2 py-1 ml-2 text-sm text-white bg-yellow-600 rounded-sm hover:bg-yellow-500 sm:text-xl">
									<FaSquareArrowUpRight className="inline-block" /> Switch to
									Milton
								</button>
							</Link>
						</h1>
						<div className="col-md-12 ">
							<h1 className="controlPanelHeadingMobile">Control Panel</h1>

							<div className="flex flex-wrap justify-center gap-2 mt-2">
								{/* Add Clients Btn */}
								<button
									onClick={handleToggleWaitlist}
									className="px-2 border-2 rounded-sm bg-cyan-300 hover:bg-cyan-200 border-cyan-700"
								>
									Waitlist <CgSandClock className="inline-block" />
								</button>
								<button
									onClick={clientsCambridgeHandler}
									className="px-2 py-1 bg-yellow-100 border-2 border-yellow-700 rounded-sm hover:bg-yellow-50"
								>
									Clients Table <i className="fas fa-users"></i>
								</button>

								{/* See calendars */}
								{!toggleCalendars ? (
									<button
										className="px-2 py-1 text-white rounded-sm bg-pink hover:bg-pink-400"
										onClick={handleToggleCalendars}
									>
										Schedule <i className="fas fa-clipboard-list"></i>
									</button>
								) : null}

								{/* Search client by phone Btn */}
								<button
									className="px-2 py-1 bg-green-300 border-2 border-green-700 rounded-sm hover:bg-green-200"
									onClick={toggleFormToSearchByPhone}
								>
									Search Clients <i className="fas fa-search"></i>
								</button>

								<button
									color="dark"
									className="px-2 py-1 text-white rounded-sm bg-slate-700 hover:bg-slate-500"
									onClick={toggleSearchAppointments}
								>
									<i className="fas fa-search"></i> All Appointments Search
								</button>
							</div>
						</div>
					</div>
					<hr />

					{searchAppointmentsForm ? (
						<div className="mx-auto border shadow-lg w-50">
							<form onSubmit={handleSearchAppointmentsSubmit}>
								<input
									placeholder="Search by: phone, pet name, breed."
									onChange={(e) => setSearchAppointmentField(e.target.value)}
									required
									className="input-search-appointments"
									type="text"
									name="searchAppointmentField"
								/>
								<div className="text-center">
									<button className="m-2 btn btn-info" type="submit">
										Search All Calendars <i className="fas fa-search"></i>
									</button>
								</div>
							</form>
						</div>
					) : null}

					{localStorage.getItem("USERNAME") === "paoyepes" ||
					localStorage.getItem("USERNAME") === "alexyepes" ? (
						//  MODAL TO ADD CLIENTS
						<ModalToAddClients
							city={city}
							isOpen={modalToAddClients}
							toggle={() => setModalToAddClients(!modalToAddClients)}
						/>
					) : (
						// MODAL TO SHOW OPTION DISABLED CLIENTS
						<ModalOptionAddClientsDisabled
							isOpen={modalToAddClients}
							toggle={() => setModalToAddClients(!modalToAddClients)}
						/>
					)}
				</div>

				{showClientsCambridgeTable && (
					<ClientsCambridge
						showClientsCambridgeTable={showClientsCambridgeTable}
						clientsCambridge={clientsCambridge}
						handleDeleteClient={handleDeleteClient}
					/>
				)}

				{waitList ? <WaitListAdmin /> : null}

				{toggleSearchClientByPhoneForm ? (
					<SearchClients clients={clients} />
				) : null}

				{toggleCalendars && (
					<div>
						<div className="flex-row justify-between hidden gap-2 sm:flex">
							<button
								className="px-2 text-xl text-white bg-black border"
								onClick={() => handleNavigation("back")}
							>
								&#x2190; All Calendars Back
							</button>
							<button
								className="px-2 text-xl text-white bg-black border"
								onClick={() =>
									setDates({
										paola: new Date(),
										claudia: new Date(),
										peppa: new Date(),
										adriana: new Date(),
										mercedes: new Date(),
										bathCambridge: new Date(),
									})
								}
							>
								RESET All Calendars
							</button>
							<button
								className="px-2 text-xl text-white bg-black border"
								onClick={() => handleNavigation("next")}
							>
								All Calendars Next &rarr;
							</button>
						</div>
						<div className="row">
							<div
								id="CambridgPaolaCalendar"
								className="col-xl-4 col-md-4 col-xs-12 cal-all"
							>
								<MainCalendar
									isBather={false}
									city="cambridge"
									groomer={"paola"}
									date={dates.paola}
									onNavigate={(newDate) =>
										handleCalendarNavigate("paola", newDate)
									}
									step={15}
									timeSlots={4}
									min={new Date(2019, 10, 0, 8, 30, 0)}
									max={new Date(2019, 10, 0, 19, 0, 0)}
									isModalOpen={modalCambridgeCal}
								/>
							</div>
							<div
								id="Emp4Calendar"
								className="col-xl-4 col-md-4 col-xs-12 cal-all"
							>
								<MainCalendar
									city="cambridge"
									isBather={false}
									isTrainee
									groomer={"peppa"}
									date={dates.peppa}
									onNavigate={(newDate) =>
										handleCalendarNavigate("peppa", newDate)
									}
									step={15}
									timeSlots={2}
									min={new Date(2019, 10, 0, 7, 0, 0)}
									max={new Date(2019, 10, 0, 15, 0, 0)}
								/>
							</div>
							<div
								id="Emp4Calendar"
								className="col-xl-4 col-md-4 col-xs-12 cal-all"
							>
								<MainCalendar
									city="cambridge"
									isBather={true}
									groomer={"bath_Cambridge"}
									date={dates.bath_Cambridge}
									onNavigate={(newDate) =>
										handleCalendarNavigate("bath_Cambridge", newDate)
									}
									step={15}
									timeSlots={2}
									min={new Date(2019, 10, 0, 7, 0, 0)}
									max={new Date(2019, 10, 0, 15, 0, 0)}
								/>
							</div>
							<div
								id="Emp4Calendar"
								className="col-xl-4 col-md-4 col-xs-12 cal-all"
							>
								<MainCalendar
									isBather={false}
									city="cambridge"
									groomer={"mercedes"}
									date={dates.mercedes}
									onNavigate={(newDate) =>
										handleCalendarNavigate("mercedes", newDate)
									}
									step={15}
									timeSlots={2}
									min={new Date(2019, 10, 0, 7, 0, 0)}
									max={new Date(2019, 10, 0, 19, 0, 0)}
								/>
							</div>
							<div
								id="Emp6Calendar"
								className="col-xl-4 col-md-4 col-xs-12 cal-all"
							>
								<MainCalendar
									isBather={false}
									city="cambridge"
									groomer={"adriana"}
									date={dates.adriana}
									onNavigate={(newDate) =>
										handleCalendarNavigate("adriana", newDate)
									}
									step={15}
									timeSlots={2}
									min={new Date(2019, 10, 0, 7, 0, 0)}
									max={new Date(2019, 10, 0, 19, 0, 0)}
								/>
							</div>
							<div
								id="Emp1Calendar"
								className={
									localStorage.getItem("USERNAME") === "paoyepes"
										? "col-xl-6 col-md-6 col-xs-12 cal-all"
										: "col-xl-4 col-md-4 col-xs-12 cal-all"
								}
							>
								<MainCalendar
									isBather={false}
									city="cambridge"
									groomer={"claudia"}
									date={dates.claudia}
									onNavigate={(newDate) =>
										handleCalendarNavigate("claudia", newDate)
									}
									step={15}
									timeSlots={2}
									min={new Date(2019, 10, 0, 7, 0, 0)}
									max={new Date(2019, 10, 0, 19, 0, 0)}
								/>
							</div>
						</div>
					</div>
				)}
				<ModalSearchResult
					modalSearchResults={modalSearchResult}
					toggleModalSearchResults={toggleModalSearchResults}
					searchResultsDetails={searchResultsDetails}
					searchAppointmentField={searchAppointmentField}
					setSearchResultsDetails={setSearchResultsDetails}
					triggerFetchEvents={triggerFetchEvents}
					setTriggerFetchEvents={setTriggerFetchEvents}
				/>
			</div>
		)
	} else {
		return (
			<div
				style={{
					marginTop: "100px",
					marginLeft: "10%",
					fontSize: "30px",
					height: "100vh",
				}}
			>
				You don't have permission to access this page
				<span role="img" aria-label="Face With Rolling Eyes Emoji">
					🙄
				</span>
			</div>
		)
	}
}

export default Index
