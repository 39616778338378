import React, { useState, useEffect } from "react"
import { Button, Modal, ModalHeader, ModalBody } from "reactstrap"
import LoadPage from "../../LoadingPage"
import axios from "axios"
import UpdatePasswordForm from "../../EditClients/updatePasswordForm"
import { RiLockPasswordFill } from "react-icons/ri"

const Index = ({
	clientId,
	modalToUpdateOwner,
	toggleModalToUpdateOwner,
	firstName,
	lastName,
	primaryPhoneNumber,
	cellphone,
	workPhone,
	address,
	email,
	getClientInformation,
	locationSelected,
	isPasswordTemporary,
}) => {
	const [loadingAxiosReq, setLoadingAxiosReq] = useState(false)
	const [showBackButton, setShowBackButton] = useState(true)
	const [showPasswordChangeForm, setShowPasswordChangeForm] = useState(false)
	const [errorMessage, setErrorMessage] = useState("")
	const [firstNameEdited, setFirstNameEdited] = useState("")
	const [lastNameEdited, setLastNameEdited] = useState("")
	const [primaryPhoneNumberEdited, setPrimaryPhoneNumberEdited] = useState("")
	const [cellPhoneNumberEdited, setCellPhoneNumberEdited] = useState("")
	const [workPhoneNumberEdited, setWorkPhoneNumberEdited] = useState("")
	const [emailEdited, setEmailEdited] = useState("")
	const [addressEdited, setAddressEdited] = useState("")

	useEffect(() => {
		setFirstNameEdited(firstName)
		setLastNameEdited(lastName)
		setPrimaryPhoneNumberEdited(primaryPhoneNumber)
		setCellPhoneNumberEdited(cellphone)
		setWorkPhoneNumberEdited(workPhone)
		setAddressEdited(address)
		setEmailEdited(email)
	}, [
		firstName,
		lastName,
		primaryPhoneNumber,
		cellphone,
		workPhone,
		email,
		address,
	])

	const updateOwnerHandler = async (e) => {
		e.preventDefault()
		if (
			!lastNameEdited ||
			!firstNameEdited ||
			!primaryPhoneNumberEdited ||
			!emailEdited ||
			!addressEdited
		) {
			const fieldsMissing = []
			if (!lastNameEdited) {
				fieldsMissing.push("Last Name")
			}
			if (!firstNameEdited) {
				fieldsMissing.push("First Name")
			}
			if (!primaryPhoneNumberEdited) {
				fieldsMissing.push("Primary phone")
			}
			if (!addressEdited) {
				fieldsMissing.push("Address")
			}
			if (!emailEdited) {
				fieldsMissing.push("Email")
			}
			return setErrorMessage(
				`Missing fields: ${fieldsMissing.map((field) => ` ${field}`)}`
			)
		}
		setErrorMessage("")
		setLoadingAxiosReq(true)
		const updatedOwner = {
			firstName: firstNameEdited.replace(/^./, (str) => str.toUpperCase()),
			lastName: lastNameEdited.replace(/^./, (str) => str.toUpperCase()),
			primaryPhoneNumber: primaryPhoneNumberEdited.replace(/[- )(]/g, ""),
			cellphone: cellPhoneNumberEdited.replace(/[- )(]/g, ""),
			workPhone: workPhoneNumberEdited.replace(/[- )(]/g, ""),
			address: addressEdited,
			email,
		}

		await axios
			.put(`/api/clients/${clientId}`, updatedOwner)
			.then(() => {
				getClientInformation()
				setTimeout(() => {
					setLoadingAxiosReq(false)
					toggleModalToUpdateOwner()
				}, 1000)
			})
			.catch((error) => {
				setLoadingAxiosReq(false)
				console.log(error)
			})
	}
	return (
		<Modal
			modalClassName="modal-success-app"
			isOpen={modalToUpdateOwner}
			toggle={toggleModalToUpdateOwner}
		>
			<ModalBody>
				<div>
					<button
						onClick={toggleModalToUpdateOwner}
						className="bg-slate-500 text-white px-1 absolute"
					>
						X
					</button>
					<h4 className="text-center">Update Owner</h4>
					{showBackButton && (
						<button
							onClick={() => setShowPasswordChangeForm(!showPasswordChangeForm)}
							className="btn btn-warning w-full"
						>
							{!showPasswordChangeForm ? (
								<span>
									<RiLockPasswordFill className="inline-block mr-2" />
									Update Password
								</span>
							) : (
								<span>&#x2190;</span>
							)}
						</button>
					)}

					{showPasswordChangeForm ? (
						<UpdatePasswordForm
							isPasswordTemporary={isPasswordTemporary}
							email={email}
							toggleModalToUpdateOwner={toggleModalToUpdateOwner}
							modalToUpdateOwner={modalToUpdateOwner}
							setShowPasswordChangeForm={setShowPasswordChangeForm}
							showBackButton={showBackButton}
							setShowBackButton={setShowBackButton}
							firstName={firstName}
							mode={"update"}
						/>
					) : (
						<form className="form-group" onSubmit={updateOwnerHandler}>
							<div className="input-field-registration">
								<label className="mt-2" htmlFor="firstName">
									* First Name
								</label>
								<input
									id="firstName"
									className="form-control"
									type="text"
									placeholder="* First Name"
									value={firstNameEdited}
									name="firstName"
									onChange={(e) => setFirstNameEdited(e.target.value)}
								/>
							</div>
							<div className="input-field-registration">
								<label className="mt-2" htmlFor="lastName">
									* Last Name
								</label>
								<input
									id="lastName"
									className="form-control"
									type="text"
									placeholder="* Last Name"
									value={lastNameEdited}
									name="lastName"
									onChange={(e) => setLastNameEdited(e.target.value)}
								/>
							</div>
							<div className="input-field-registration">
								<label className="mt-2" htmlFor="primaryPhoneNumber">
									* Primary Phone
								</label>
								<input
									id="primaryPhoneNumber"
									className="form-control"
									type="text"
									placeholder="Primary Phone"
									name="primaryPhoneNumber"
									value={primaryPhoneNumberEdited}
									onChange={(e) => setPrimaryPhoneNumberEdited(e.target.value)}
								/>
							</div>
							<div className="input-field-registration">
								<label className="mt-1" htmlFor="cellPhone">
									Alternative Phone
								</label>
								<input
									id="cellPhone"
									className="form-control"
									type="text"
									placeholder="Cell"
									name="cellphone"
									value={cellPhoneNumberEdited}
									onChange={(e) => setCellPhoneNumberEdited(e.target.value)}
								/>
							</div>
							<div className="input-field-registration">
								<label className="mt-2" htmlFor="workPhone">
									Work Phone
								</label>
								<input
									id="workPhone"
									className="form-control"
									type="text"
									placeholder="Emergency Phone"
									name="workPhone"
									value={workPhoneNumberEdited}
									onChange={(e) => setWorkPhoneNumberEdited(e.target.value)}
								/>
							</div>
							<div className="input-field-registration ">
								<label className="mt-2" htmlFor="address">
									* Address
								</label>
								<input
									id="address"
									className="form-control"
									type="text"
									placeholder="Address"
									name="address"
									value={addressEdited}
									onChange={(e) => setAddressEdited(e.target.value)}
								/>
							</div>
							{/* <div className="input-field-registration">
								<label className="mt-2" htmlFor="email">
									* Email
								</label>
								<input
									id="email"
									className="form-control"
									type="email"
									placeholder="Email"
									name="email"
									value={emailEdited}
									onChange={(e) => setEmailEdited(e.target.value)}
								/>
							</div> */}

							{loadingAxiosReq ? (
								<LoadPage />
							) : (
								<div className="input-field-registration">
									<Button
										color="info"
										block
										style={{
											marginTop: "10px",
											fontWeight: "700",
											fontSize: "22px",
										}}
										className="btn-primary lighten-1 z-depth-0"
									>
										Submit
									</Button>
								</div>
							)}
						</form>
					)}
					<p className="error-message-registration text-align-center">
						{errorMessage}
					</p>
				</div>
			</ModalBody>
		</Modal>
	)
}

export default Index
