import React, {
  useState,
  useEffect,
  useContext,
  useRef,
  useLayoutEffect,
} from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { gsap } from "gsap";
import { RegistrationContext } from "../../contexts/registrationStatusContext";
import { CustomButton } from "../CustomButton";
import Cookies from "js-cookie";

const Toolbar = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [employeeUsername, setEmployerUsername] = useState("");
  const [showSubItems, setShowSubItems] = useState(false);
  const [showSubItemsRegistration, setShowSubItemsRegistration] =
    useState(false);
  const [authorized, setAuthorized] = useState(false);
  const [jobType, setJobType] = useState("");
  const [isMobileMenuOpen, setIsMoblieMenuOpen] = useState(false);
  const [showMobileServicesDropdown, setShowMobileServicesDropdown] =
    useState(false);
  const [showMobileRegistrationDropdown, setShowMobileRegistrationDropdown] =
    useState(false);

  // REFS
  const mobileMenuContainer = useRef(null);
  const mainDesktopMenuContainer = useRef(null);

  const mobileTl = useRef(null);

  const user = {
    username: "kmd",
    password: "kmd",
  };

  useEffect(() => {
    animateItemsDesktopMain();
    window.scrollTo(0, 0);
  }, []);

  const {
    isRegistrationInProcess,
    setIsRegistrationInProcess,
    setLimitedAccessToCambridgeAdmin,
    setSwitchToOldToolbar,
    setShowLoginModal,
    showLoginModal,
    setShowRegisterDaycareModal,
    setShowRegisterGroomingModal,
    isAuthenticated,
  } = useContext(RegistrationContext);

  useEffect(() => {
    if (window.innerWidth < 912) props.closeSideDrawerHandler();
    // eslint-disable-next-line
  }, [location.pathname, isRegistrationInProcess]);

  const accessString = localStorage.getItem("JWT");
  useEffect(() => {
    window.scrollTo(0, 0);
    if (accessString == null) {
      setAuthorized(false);
    } else {
      try {
        const employeeUsername = localStorage.getItem("USERNAME");
        const jobType = localStorage.getItem("JOBTYPE");
        setEmployerUsername(employeeUsername);
        setAuthorized(true);
        setJobType(jobType);
      } catch (error) {
        console.error(error);
      }
    }
  }, [accessString]);

  // 	//Logout User
  const handleLogOut = (e) => {
    e.preventDefault();
    localStorage.removeItem("JWT");
    localStorage.removeItem("USERNAME");
    localStorage.removeItem("JOBTYPE");
    localStorage.removeItem("CITY");
    localStorage.removeItem("LIMITED_ACCESS_TO_CAMBRIDGE");
    localStorage.removeItem("OLD_TOOLBAR");
    setSwitchToOldToolbar(false);
    setLimitedAccessToCambridgeAdmin(false);
    window.location.href = "/";
  };

  const registrationStatusChecker = (navLink) => {
    if (!isRegistrationInProcess) {
      return;
    } else if (
      window.confirm(
        "Your registration is not complete yet! Are you sure you want to leave? Your progress will be lost"
      )
    ) {
      setIsRegistrationInProcess(false);
      localStorage.clear();
      navigate(`/${navLink}`);
    }
  };

  //Animation for desktop menu.
  const animateItemsDesktopMain = () => {
    let ctx = gsap.context(() => {
      const tl = gsap.timeline();
      tl.fromTo(
        ".itemMainDesktopMenu",
        {
          opacity: 0,
          y: 20,
        },
        {
          opacity: 1,
          y: 0,
          duration: 0.5,
          stagger: 0.1,
        }
      );
    }, mainDesktopMenuContainer);
    return () => ctx.revert();
  };

  // MOBILE: Animation for mobile menu.
  const handleMobileMenuClicked = () => {
    setIsMoblieMenuOpen(!isMobileMenuOpen);
    if (!isMobileMenuOpen) {
      handleOpenMobileMenu();
    } else {
      handleCloseMobileMenu();
    }
  };
  const handleCloseMobileMenu = () => {
    mobileTl.current.duration(0.8);
    mobileTl.current.reverse();
    setShowMobileRegistrationDropdown(false);
  };
  const handleOpenMobileMenu = () => {
    mobileTl.current.duration(2);
    mobileTl.current.play();
  };

  useLayoutEffect(() => {
    let ctx = gsap.context(() => {
      mobileTl.current = gsap.timeline({ paused: true });
      const tl = mobileTl.current;

      //Animate burger icon
      tl.to(".topLine", {
        y: 5,
        duration: 0.3,
      });
      tl.to(
        ".bottomLine",
        {
          y: -5,
          duration: 0.3,
        },
        "-=0.3"
      );

      //Open menu
      tl.fromTo(
        ".menuContainer",
        {
          clipPath: "circle(60px at 90% 5%)",
        },
        {
          clipPath: "circle(100% at 50% 50%)",
          duration: 1.5,
          ease: "power3.out",
        }
      );
      //Animate menu items
      tl.fromTo(
        ".itemMobileMenu",
        {
          opacity: 0,
          y: 20,
        },
        {
          opacity: 1,
          y: 0,
          duration: 0.5,
          stagger: 0.1,
        },
        "-=1.5"
      );

      //Animate burger icon
      tl.to(
        ".topLine",
        {
          rotate: 45,
          duration: 0.3,
        },
        "-=1.5"
      );
      tl.to(
        ".bottomLine",
        {
          rotate: -45,
          duration: 0.3,
        },
        "-=1.5"
      );
    }, mobileMenuContainer);

    return () => ctx.revert();
  }, []);

  const logoutHandler = () => {
    Cookies.remove("authtoken");
    Cookies.remove("client_location");
    localStorage.removeItem("CITY");
    window.location.reload();
  };

  return (
    <nav className={`w-full items-center justify-start z-[9999]`}>
      {/* Desktop */}
      <div className="fixed bg-white z-[1000] w-full top-0">
        <ul
          className={`md:flex hidden flex-row items-center justify-between pt-phone px-phone md:px-tablet lg:px-desktop`}
          ref={mainDesktopMenuContainer}
        >
          <li
            className="cursor-pointer select-none itemMainDesktopMenu"
            onClick={() => {
              isRegistrationInProcess && registrationStatusChecker("");
              window.scrollTo(0, 0);
            }}
          >
            <Link to={!isRegistrationInProcess && "/"} className="text-black">
              <img
                className="w-[100px]"
                src="/images/newLogo.png"
                alt="Amazing pet grooming logo"
              />
            </Link>
          </li>
          <li>
            <ul className="w-full flex items-center gap-[20px] text-black relative">
              {!authorized ? (
                <>
                  <li
                    className="relative z-10 cursor-pointer select-none itemMainDesktopMenu"
                    onMouseEnter={() => setShowSubItems(true)}
                    onMouseLeave={() => setShowSubItems(false)}
                  >
                    <div className="text-main hover:text-[#019DFF] hover:no-underline">
                      Services
                    </div>
                    {/* Submenu for Daycare */}
                    {showSubItems && (
                      <ul className="absolute bg-white py-[10px] px-[20px] top-full left-0 border-[1px] border-main rounded-default rounded-tl-none transition-all duration-300 flex flex-col gap-2 subMenu">
                        <li>
                          <Link
                            to={!isRegistrationInProcess && "/daycare"}
                            className="text-main hover:text-[#019DFF] hover:no-underline"
                          >
                            Daycare
                          </Link>
                        </li>
                        <li>
                          <Link
                            to={!isRegistrationInProcess && "/services"}
                            className="text-main hover:text-[#019DFF] hover:no-underline"
                          >
                            Grooming
                          </Link>
                        </li>
                      </ul>
                    )}
                  </li>
                  <li
                    className="cursor-pointer select-none itemMainDesktopMenu"
                    onClick={() => {
                      isRegistrationInProcess &&
                        registrationStatusChecker("about");
                    }}
                  >
                    <Link
                      to={!isRegistrationInProcess && "/about"}
                      className="text-main hover:text-[#019DFF] hover:no-underline"
                    >
                      About us
                    </Link>
                  </li>
                  <li
                    className="cursor-pointer select-none itemMainDesktopMenu"
                    onClick={() => {
                      isRegistrationInProcess &&
                        registrationStatusChecker("gallery");
                    }}
                  >
                    <Link
                      to={!isRegistrationInProcess && "/gallery"}
                      className="text-main hover:text-[#019DFF] hover:no-underline"
                    >
                      Gallery
                    </Link>
                  </li>
                  <li
                    className="cursor-pointer select-none itemMainDesktopMenu"
                    onClick={() => {
                      isRegistrationInProcess &&
                        registrationStatusChecker("contact");
                    }}
                  >
                    <Link
                      to={!isRegistrationInProcess && "/contact"}
                      className="text-main hover:text-[#019DFF] hover:no-underline"
                    >
                      Contact
                    </Link>
                  </li>

                  {isAuthenticated ? (
                    <>
                      <li className="p-2 bg-blue-100 rounded-sm">
                        <Link
                          to={!isRegistrationInProcess && "/client-dashboard"}
                          className="text-main mt-4 hover:text-[#019DFF] hover:no-underline"
                        >
                          Profile
                        </Link>
                      </li>
                      <button
                        className="px-4 py-2 font-bold text-black bg-yellow-300 rounded-md hover:bg-yellow-400"
                        onClick={logoutHandler}
                      >
                        Logout
                      </button>
                    </>
                  ) : (
                    <>
                      <li
                        className="relative z-10 cursor-pointer select-none itemMainDesktopMenu"
                        onMouseEnter={() => setShowSubItemsRegistration(true)}
                        onMouseLeave={() => setShowSubItemsRegistration(false)}
                      >
                        <div className="text-main hover:text-[#019DFF] hover:no-underline">
                          Register
                        </div>
                        {showSubItemsRegistration && (
                          <ul
                            className={`absolute left-0 py-[10px] px-[20px] bg-white border-[1px] border-main rounded-default rounded-tl-none transition-all duration-300 flex flex-col gap-2 subMenu`}
                          >
                            <li>
                              <div
                                onClick={() =>
                                  setShowRegisterDaycareModal(true)
                                }
                                className="text-main hover:text-[#019DFF] hover:no-underline"
                              >
                                Daycare
                              </div>
                            </li>
                            <li>
                              <Link
                                // to={"/grooming_registration"}
                                onClick={() =>
                                  setShowRegisterGroomingModal(true)
                                }
                                className="text-main mt-4 hover:text-[#019DFF] hover:no-underline"
                              >
                                Grooming
                              </Link>
                            </li>
                          </ul>
                        )}
                      </li>
                      <li
                        className="cursor-pointer select-none itemMainDesktopMenu"
                        onClick={() => {
                          isRegistrationInProcess &&
                            registrationStatusChecker("contact");
                        }}
                      >
                        <div
                          onClick={() => setShowLoginModal(!showLoginModal)}
                          // to={!isRegistrationInProcess && "/login"}
                          className="text-main hover:text-[#019DFF] hover:no-underline"
                        >
                          Login
                        </div>
                      </li>
                      <li
                        className="cursor-pointer select-none itemMainDesktopMenu"
                        onClick={() => {
                          isRegistrationInProcess &&
                            registrationStatusChecker("schedule");
                        }}
                      >
                        <Link
                          to={!isRegistrationInProcess && "/schedule"}
                          className="hover:no-underline"
                        >
                          <CustomButton onClick={() => {}} hasArrow>
                            Book Grooming
                          </CustomButton>
                        </Link>
                      </li>
                    </>
                  )}
                </>
              ) : (
                <>
                  <li className="text-main hover:text-[#019DFF] hover:no-underlin itemMainDesktopMenu">
                    👤 Logged in as: {employeeUsername}
                  </li>
                  {jobType === "receptionist" ||
                    (jobType === "groomer1" && (
                      <>
                        {jobType === "receptionist" && (
                          <li className="cursor-pointer select-none itemMainDesktopMenu">
                            <Link
                              to={{
                                pathname: "/auth/commission",
                              }}
                              className="text-main hover:text-[#019DFF] hover:no-underline"
                            >
                              💰 Daily Report
                            </Link>
                          </li>
                        )}
                        <li className="cursor-pointer select-none itemMainDesktopMenu">
                          <Link
                            to="/auth/reception"
                            className="text-main hover:text-[#019DFF] hover:no-underline"
                          >
                            ⚙️ Control Panel
                          </Link>
                        </li>
                      </>
                    ))}
                  {jobType === "admin" && (
                    <li className="cursor-pointer select-none itemMainDesktopMenu">
                      <Link
                        to="/auth/admin"
                        className="text-main hover:text-[#019DFF] hover:no-underline"
                      >
                        Admin panel
                      </Link>
                    </li>
                  )}
                  <li className="cursor-pointe itemMainDesktopMenu">
                    <CustomButton onClick={handleLogOut}>Log out</CustomButton>
                  </li>
                </>
              )}
            </ul>
          </li>
        </ul>
      </div>

      {/* Mobile */}
      <div className="md:hidden" ref={mobileMenuContainer}>
        <div
          className="bg-lightBlue fixed top-0 left-0 w-full h-[100%] px-[30px] py-[50px] menuContainer z-[1000]"
          style={{
            clipPath: "circle(30px at 90% 5%)",
          }}
        >
          {/* Burger icon */}
          <div
            className="absolute w-[50px] h-[50px] rounded-full left-[85%] top-[3%] md:right-[50px] md:top-[50px] grid gap-[5px] place-content-center z-[9999]"
            onClick={handleMobileMenuClicked}
          >
            <span
              className="w-[30px] h-[5px] rounded-[5px] bg-main topLine line"
              style={{ transformOrigin: "center center" }}
            />

            <span
              className="w-[30px] h-[5px] rounded-[5px] bg-main bottomLine line"
              style={{ transformOrigin: "center center" }}
            />
          </div>

          <ul
            className="flex flex-col items-center gap-[10px] text-main font-semibold w-full text-[6vw]"
            id="navBarUl"
          >
            <li
              className="cursor-pointer itemMobileMenu"
              onClick={() => {
                handleMobileMenuClicked();
                isRegistrationInProcess && registrationStatusChecker("");
              }}
            >
              <Link to={!isRegistrationInProcess && "/"} className="text-black">
                <img
                  className="w-[100px]"
                  src="/images/logo_300.png"
                  alt="Amazing pet grooming logo"
                />
              </Link>
            </li>
            <>
              <li
                className="cursor-pointer itemMobileMenu"
                onClick={() => {
                  setShowMobileServicesDropdown(!showMobileServicesDropdown);
                  isRegistrationInProcess &&
                    registrationStatusChecker("services");
                }}
              >
                <span>Services</span>
              </li>
              {showMobileServicesDropdown && (
                <>
                  <li
                    className="items-start ml-2 text-sm cursor-pointer"
                    onClick={() => {
                      handleMobileMenuClicked();
                      isRegistrationInProcess &&
                        registrationStatusChecker("daycare");
                      setShowMobileServicesDropdown(false);
                    }}
                  >
                    <Link
                      to={!isRegistrationInProcess && "/daycare"}
                      className="text-main"
                    >
                      &#8226; Daycare
                    </Link>
                  </li>
                  <li
                    className="items-start ml-2 text-sm cursor-pointer"
                    onClick={() => {
                      handleMobileMenuClicked();
                      isRegistrationInProcess &&
                        registrationStatusChecker("services");
                      setShowMobileServicesDropdown(false);
                    }}
                  >
                    <Link
                      to={!isRegistrationInProcess && "/services"}
                      className="text-main"
                    >
                      &#8226; Grooming
                    </Link>
                  </li>
                </>
              )}

              <li
                className="cursor-pointer itemMobileMenu"
                onClick={() => {
                  handleMobileMenuClicked();
                  isRegistrationInProcess && registrationStatusChecker("about");
                }}
              >
                <Link
                  to={!isRegistrationInProcess && "/about"}
                  className="text-main"
                >
                  About us
                </Link>
              </li>
              <li
                className="cursor-pointer itemMobileMenu"
                onClick={() => {
                  handleMobileMenuClicked();
                  isRegistrationInProcess &&
                    registrationStatusChecker("gallery");
                }}
              >
                <Link
                  to={!isRegistrationInProcess && "/gallery"}
                  className="text-main"
                >
                  Gallery
                </Link>
              </li>
              <li
                className="cursor-pointer itemMobileMenu"
                onClick={() => {
                  handleMobileMenuClicked();
                  isRegistrationInProcess &&
                    registrationStatusChecker("contact");
                }}
              >
                <Link
                  to={!isRegistrationInProcess && "/contact"}
                  className="text-main"
                >
                  Contact
                </Link>
              </li>

              {isAuthenticated ? (
                <>
                  <li className="p-2 bg-blue-100 rounded-sm">
                    <Link
                      onClick={handleCloseMobileMenu}
                      to={!isRegistrationInProcess && "/client-dashboard"}
                      className="text-main mt-4 hover:text-[#019DFF] hover:no-underline"
                    >
                      Profile
                    </Link>
                  </li>
                  <button
                    className="px-4 py-2 font-bold text-black bg-yellow-300 rounded-md hover:bg-yellow-400"
                    onClick={() => {
                      logoutHandler();
                      handleCloseMobileMenu();
                    }}
                  >
                    Logout
                  </button>
                </>
              ) : (
                <>
                  <li
                    className="cursor-pointer itemMobileMenu"
                    onClick={() => {
                      isRegistrationInProcess &&
                        registrationStatusChecker("contact");
                    }}
                  >
                    <div
                      onClick={() => setShowLoginModal(!showLoginModal)}
                      // to={!isRegistrationInProcess && "/login"}
                      className="text-main hover:text-[#019DFF] hover:no-underline"
                    >
                      Login
                    </div>
                  </li>
                  <li
                    className="cursor-pointer itemMobileMenu"
                    onClick={() => {
                      setShowMobileRegistrationDropdown(
                        !showMobileRegistrationDropdown
                      );
                    }}
                  >
                    <span>Register</span>
                    {/* <Link
											to={!isRegistrationInProcess && "/grooming_registration"}
											className="text-main"
										>
											Register
										</Link> */}
                  </li>
                  {showMobileRegistrationDropdown && (
                    <>
                      <span
                        className="text-sm text-main"
                        onClick={() => {
                          setShowRegisterGroomingModal(true);
                          setShowMobileRegistrationDropdown(false);
                          handleCloseMobileMenu();
                        }}
                      >
                        &#8226; Grooming
                      </span>
                      <span
                        className="text-sm text-main"
                        onClick={() => {
                          setShowRegisterDaycareModal(true);
                          setShowMobileRegistrationDropdown(false);
                          handleCloseMobileMenu();
                        }}
                      >
                        &#8226; Daycare
                      </span>
                    </>
                  )}
                  <li
                    className="mt-2 cursor-pointer itemMobileMenu"
                    onClick={() => {
                      handleMobileMenuClicked();
                      isRegistrationInProcess &&
                        registrationStatusChecker("schedule");
                    }}
                  >
                    <Link to={!isRegistrationInProcess && "/schedule"}>
                      <CustomButton onClick={() => {}} hasArrow>
                        Book Grooming
                      </CustomButton>
                    </Link>
                  </li>
                </>
              )}
            </>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Toolbar;
