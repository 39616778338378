const DAYCARE_TRIAL_TIME_MORNING = "Any time between 9am and 11am";
const DAYCARE_TRIAL_TIME_AFTERNOON = "Any time between 2pm and 4pm";

const services = [
  {
    name: "Spa",
    description:
      "Amazing Pet Grooming has packages to suit different needs and budgets. From the Basic Bath and Brush package, to the Premium Full Service Groom, Amazing Pet Grooming provides a variety of options for your pet. Take your pets to the professionals, after all… they deserve it.",
    icon: "🛁",
  },
  {
    name: "Haircut",
    description:
      "Haircuts and Styling are frequently needed based on the breed. Our professional dog/cat groomers have extensive experience on all styles and breeds. We will custom design your dog's/cat's haircut based on your taste and requirements.",
    icon: "✂️",
  },
  {
    name: "Drying and Brushing",
    description:
      "Drying and brushing is one of the most important steps in grooming your dog. By removing all matted and loose fur before the haircut, your dog and cat will come out looking completely fantastic.",
    icon: "🧼",
  },
  {
    name: "Ear cleaning",
    description:
      "Dog's and cat's ears are very sensitive and susceptible to infection, excessive wax buildup and parasites. A gentle cleaning with proper products will eliminate the majority of those problems. By removing the fur inside their ears or plucking them, it will help maintain their ears in a healthy condition.",
    icon: "🧽",
  },
  {
    name: "Bathing",
    description:
      "We bathe your dog with mild shampoos that are safe to use with Front-line and Advantage and other spot-on products. We express the anal gland before bathing (upon request). Regular bathing keeps your dog's skin healthy, and his/her coat clean and shiny.",
    icon: "🚿",
  },
  {
    name: "Nail Trimming",
    description:
      "Nail trimming is an essential part of dog/cat grooming, and trimmed nails are one clear sign of your dog’s good health and hygiene. Dogs and Cats need to have their nails trimmed at least every 4-6 weeks in order to maintain their quick nice and short; in addition, grinding their nails will reduce the sharpness after trimming them.",
    icon: "🐾",
  },
  {
    name: "Teeth Cleaning",
    description:
      "Teeth Cleaning on a regular basis will reduce tartar and help prevent periodontal disease. Brushing is important because it cleans away the plaque that leads to bad breath or more serious problems such as decayed teeth or gum disease.",
    icon: "🦷",
  },
  {
    name: "Dematting",
    description:
      "Dematting is a very important task in grooming. Hairs stuck together and shaggy hairs do not only provide dogs and cats an ill-kempt appearance, but they are also the hiding place for different kinds of parasites, and it may be also the hotbed of diseases. De-Tangling services can be provided when coat has been maintained properly by brushing it out regularly.",
    icon: "🪮",
  },
  {
    name: "Cat Services",
    description:
      "Only the most experienced groomers are able to handle these precious felines, and we are really proud to say that we have one of the best Cat Groomers in Ontario. From the basic nail trim to the premium package that includes a bath and a haircut, cat services are offered at both locations. Lion cuts, dragon cuts, you name it, and we will make it a reality.",
    icon: "🐈",
  },
  // Agrega más objetos de servicios según tus necesidades
];

const reviews = [
  {
    name: "Leila Correira",
    image: "/images/review1.png",
    review:
      "My little Milo always comes looking so handsome. I have been maybe four times now and I will continue going as the staff there are so caring and loving for the animals Milo seems to love it there because he even sits on their lap watching the other dogs get groomed and he is very calm. I recommend amazing pet groomer to everyone that asks me where I got him groomed. Overall extremely pleased.",
  },
  {
    name: "Melanie Ide",
    image: "/images/review2.png",
    review:
      "Used them for Mylos first puppy groom and was very happy with the service 😊 will definitely be back",
  },
];

const locations = [
  {
    name: "Milton",
    phoneNumbers: "905 878-9009 🐾 905 878-5557",
    address: "264 Bronte St S, Unit 15 - Milton, ON - L9T 5A3",
    map: {
      title: "Amazing Pet Grooming - Cambridge",
      link: "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11571.849867984401!2d-79.8709564!3d43.5239754!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xa1616d6c9e9e1545!2sAmazing%20Pet%20Grooming!5e0!3m2!1sen!2sca!4v1647126390955!5m2!1sen!2sca",
    },
  },
  {
    name: "Cambridge",
    phoneNumbers: "647 368-7220",
    address: "383 Elgin St N Unit #1 - Cambridge, ON - N1R 8C1",
    map: {
      title: "Amazing Pet Grooming - Cambridge",
      link: "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11599.61280696455!2d-80.305793!3d43.379048!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x36e44a611a5e047e!2sAmazing%20Pet%20Grooming!5e0!3m2!1sen!2sca!4v1647126027541!5m2!1sen!2sca",
    },
  },
];

const teamMembers = {
  paola: {
    name: "Paola",
    image: "/images/paula-main.png",
    color: "#DF2C7C",
    description:
      "Paula, (or 'The Cat Whisperer', as some people call her) has been in the pet grooming industry for over 20 years. <i>Graduated from the Nash Pet Grooming Academy in Kentucky</i>, she has dedicated most of her life to work with dogs and cats. She's passionate about nature and animal care. Her vast experience with all kind of dogs and cats is something we have the privilege to share with our customers.",
  },
  claudia: {
    name: "Claudia",
    image: "/images/claudia-main.png",
    color: "#9F4FD2",
    description:
      "With over 15 years in the animal care field, Claudia has always been passionate about dogs. She strives to make the grooming experience as stress-free as possible. She believes it is a must to have a great deal of patience and trust, as well as the ability to read the dogs' body language. Claudia's motto is: <i>'I treat our customers' pets the same way I want my pets to be treated when I am not by their side' .</i>",
  },
  adriana: {
    name: "Adriana",
    image: "/images/adriana-main.png",
    color: "#db767a",
    description:
      "Meet Adriana! She is a seasoned dog groomer with over two decades of experience working with animals. With a profound passion for pets, she has dedicated her career to providing top-notch grooming services. Adriana's expertise goes beyond grooming techniques; she prioritizes building meaningful relationships with the dogs in her care. Her gentle and patient approach creates a calming atmosphere, ensuring each furry client feels comfortable and safe. With a wealth of experience and a heart full of love for animals, Adriana continues to make a positive impact in their lives of pets and their adoring owners. Trust her to give your furry friend the best grooming experience possible.",
  },
  mercedes: {
    name: "Mercedes",
    image: "/images/mercedes-main.png",
    color: "#FFF8DC",
    description:
      "Meet Mercedes! With over three decades of experience working with animals, Mercedes provides not only experience but also an unparalleled level of dedication and passion for the well-being of all creatures great and small. Her journey began as a young volunteer at a local animal shelter, where she quickly discovered her innate connection with animals and her desire to make a difference in their lives. Beyond her professional accomplishments, Mercedes's true strength lies in her ability to forge meaningful connections with animals. She believes in fostering a harmonious relationship between people and animals, advocating for empathy, respect, and understanding in all interactions.",
  },
  diana: {
    name: "Diana",
    image: "/images/diana-main.png",
    color: "#3977EC",
    description:
      "Diana has been working around animals for over 20 years. <i>She holds a Veterinarian diploma from a Colombian University.</i> Clients have told her that she has a special ability for working with dogs that other groomers turn away. She takes great pride in her work and is very much a Quality over Quantity type of groomer. She keeps up to date on the latest grooming trends, equipment, and products by attending trade shows and seminars.",
  },
  baths_cambridge: {
    name: "bath_Cambridge",
    image: "/images/bath_cambridge-main.png",
    color: "#C3B2AB",
  },
  peppa: {
    name: "Peppa",
    image: "/images/peppa-main.png",
    color: "#22E34D",
    description:
      "Peppa has spent over two decades immersed in the world of animal care, with her journey beginning in the vibrant landscapes of Chile. Having honed her skills and knowledge through hands-on experience, Peppa brings a unique perspective shaped by the rich biodiversity and distinct cultural influences of her homeland. She is no stranger to the challenges and joys of working with a diverse array of dog breeds. Peppa's passion for her craft is palpable, and her dedication to providing top-notch care is evident in every interaction. She remains deeply connected to her Chilean roots, drawing inspiration from its natural wonders and drawing upon traditional methods alongside modern techniques. Peppa's commitment to excellence knows no bounds, making her a trusted and invaluable asset to the animal care community, both in Chile and beyond.",
  },
  bather: {
    name: "Bather",
    image: "/images/bather-main.png",
    color: "#F3A713",
  },
};

const yesOrNoOptions = [
  {
    value: true,
    label: "Yes",
  },
  {
    value: false,
    label: "No",
  },
];

const genderOptions = [
  {
    value: "male",
    label: "Male",
  },
  {
    value: "female",
    label: "Female",
  },
];

const petTypeOptions = [
  {
    value: "dog",
    label: "Dog",
  },
  {
    value: "cat",
    label: "Cat",
  },
];

const NUM_OF_AVAILABLE_DAYCARE_SPOTS = 40;

const petObj = {
  id: "",
  name: "",
  breed: "",
  type: "dog",
  age: "",
  color: "",
  gender: "",
  weight: "",
  isFixed: false,
  fixedDate: "",
  hasBordatellaVaccine: false,
  bordatellaExpDate: "",
  hasRegularVaccine: false,
  regularShotsExpireDate: "",
  hasAllergies: false,
  currentInjuries: "",
  generalBehaviour: "",
  vaccineRecordImgUrl: "",
  bordatellaImgUrl: "",
  petImg: null,
  notes: null,
  allowPhoto: null,
};

const daycarePassOptions = [
  {
    value: "Half Day 5 Visit pass",
    label: "Half Day 5 Visit pass",
    cost: 90,
    numOfVisits: 5,
  },
  {
    value: "Half Day 10 Visit pass",
    label: "Half Day 10 Visit pass",
    cost: 160,
    numOfVisits: 10,
  },
  {
    value: "Half Day 20 Visit pass",
    label: "Half Day 20 Visit pass",
    cost: 290,
    numOfVisits: 20,
  },
  {
    value: "Full Day 5 Visit pass",
    label: "Full Day 5 Visit pass",
    cost: 144,
    numOfVisits: 5,
  },
  {
    value: "Full Day 10 Visit pass",
    label: "Full Day 10 Visit pass",
    cost: 280,
    numOfVisits: 10,
  },
  {
    value: "Full Day 20 Visit pass",
    label: "Full Day 20 Visit pass",
    cost: 530,
    numOfVisits: 20,
  },
];

module.exports = {
  services,
  reviews,
  teamMembers,
  locations,
  yesOrNoOptions,
  genderOptions,
  petObj,
  petTypeOptions,
  NUM_OF_AVAILABLE_DAYCARE_SPOTS,
  DAYCARE_TRIAL_TIME_MORNING,
  DAYCARE_TRIAL_TIME_AFTERNOON,
  daycarePassOptions,
};
